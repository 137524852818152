import React, {FC} from 'react';
import {Button, Modal, Textarea, TextInput} from 'flowbite-react';
import {Text} from '~/modules/Common/components/Text';
import {json, LoaderFunction,} from '@shopify/remix-oxygen';
import ComponentWithLabel from '~/modules/Common/components/ComponentWithLabel';
import {ExternalLink} from "lucide-react";
import {MergedProduct} from "~/modules/Product/context/types";
import {useLoaderData, useFetcher} from "@remix-run/react";
import {useExpertLink} from "~/modules/Common/hooks/useExpertLink";
import {useGetExpertQuery} from "~/gql/generated";
import {QueryClient} from "@tanstack/react-query";
import StateDropdown from "~/modules/Product/components/StateDropdown";

interface RequestSampleProps {
  product: MergedProduct;
  isVisible: boolean;
  onClose: () => void;
}

export const loader: LoaderFunction = async ({request}) => {
  try {
    const client = new QueryClient();
    const expertQuery = await client.fetchQuery(
      useGetExpertQuery.getKey(),
      useGetExpertQuery.fetcher(),
    );

    const expert = expertQuery.getExpert;

    return json({expert});
  } catch (error) {
    console.error(error);
    return json({expert: {}});
  }
};


const RequestSampleModal: FC<RequestSampleProps> = ({ isVisible, product, onClose }) => {
  const loaderData = useLoaderData();
  const apiLink = useExpertLink('/my/api/requestsample');
  const fetcher = useFetcher();

  const expert = loaderData?.expert;
  const showSuccess = fetcher.data?.success && fetcher.state === 'idle';

  return (
    <Modal show={isVisible} onClose={onClose} size="xl" className="z-[120] h-full overflow-auto">
      <Modal.Header>Request Product Sample</Modal.Header>

      <Modal.Body>
        {!showSuccess ? (
          <>
            <div className="flex flex-col">
              <Text size="sm">Requested item:</Text>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={product?.outlink}
                className="flex items-center gap-2 underline text-sm"
              >
                {product?.outlink} <ExternalLink width={16}/>
              </a>

              <Text size="sm">
                Note: If you want a different product variant,
                please include the link to it in the message box below
              </Text>
            </div>

            <fetcher.Form
              action={apiLink}
              method="post"
              className="mt-4"
            >
              <input type="hidden" id="subscriberKey" name="subscriberKey" value={product?.brandId}/>
              <input type="hidden" id="productId" name="productId" value={product?.externalId}/>
              <input type="hidden" id="creatorId" name="creatorId" value={expert?.id}/>

              <input type="hidden" id="productType" name="productType" value={product?.brandType}/>
              <input type="hidden" id="productTitle" name="productTitle" value={product?.name || ""}/>
              <input type="hidden" id="creatorName" name="creatorName" value={expert?.name}/>
              <input type="hidden" id="firstName" name="firstName" value={expert?.contactInfo?.firstName}/>
              <input type="hidden" id="lastName" name="lastName" value={expert?.contactInfo?.lastName}/>
              <input type="hidden" id="replyAddress" name="replyAddress" value={expert?.contactInfo?.email}/>
              <input type="hidden" id="instagramHandle" name="instagramHandle"
                     value={expert?.socialMedia?.instagramName}/>
              <input type="hidden" id="tiktokHandle" name="tiktokHandle" value={expert?.socialMedia?.tiktokName}/>
              <input type="hidden" id="facebookHandle" name="facebookHandle" value={expert?.socialMedia?.facebookName}/>
              <input type="hidden" id="youtubeHandle" name="youtubeHandle" value={expert?.socialMedia?.youtubeName}/>
              <input type="hidden" id="twitterHandle" name="twitterHandle" value={expert?.socialMedia?.twitterName}/>
              <input type="hidden" id="threadsHandle" name="threadsHandle" value={expert?.socialMedia?.threadsName}/>
              <input type="hidden" id="pinterestHandle" name="pinterestHandle"
                     value={expert?.socialMedia?.pinterestName}/>
              <ComponentWithLabel label="Message">
                <Textarea
                  id="message"
                  name="message"
                  rows={10}
                  maxLength={2000}
                  placeholder="Let the brand know how you plan to promote the product"
                  required
                />
              </ComponentWithLabel>

              <ComponentWithLabel label="Mailing Address">
                <TextInput id="street1" name="street1" type="text" defaultValue={expert?.address.street_address_1} required/>
              </ComponentWithLabel>

              <ComponentWithLabel label="Apartment suite, ect. (optional)">
                <TextInput id="street2" name="street2"  defaultValue={expert?.address.street_address_2} type="text"/>
              </ComponentWithLabel>

              <div className="flex gap-2 flex-col lg:flex-row">
                <ComponentWithLabel label="City">
                  <TextInput id="city" name="city"  defaultValue={expert?.address.city}  type="text" required/>
                </ComponentWithLabel>

                <div className="flex gap-2">
                  <div>
                    <ComponentWithLabel label="State">
                      <StateDropdown
                        id="state"
                        name="state"
                        required={true}
                        defaultValue={expert?.address.state_province}
                      />
                    </ComponentWithLabel>
                  </div>

                  <ComponentWithLabel label="Zip Code">
                    <TextInput id="postalCode" name="postalCode" type="text" defaultValue={expert?.address.postal_code} required/>
                  </ComponentWithLabel>
                </div>
              </div>

              <div className="flex gap-2">
                <ComponentWithLabel label="First Name">
                  <TextInput id="mailingAddressFirstName" name="mailingAddressFirstName" type="text" defaultValue={expert?.address.mailing_address_first_name || expert?.contactInfo?.firstName} required/>
                </ComponentWithLabel>

                <ComponentWithLabel label="Last Name">
                  <TextInput id="mailingAddressLastName" name="mailingAddressLastName" type="text" defaultValue={expert?.address.mailing_address_last_name || expert?.contactInfo?.lastName} required/>
                </ComponentWithLabel>
              </div>

              <Button color={'dark'} className="mt-3 ml-auto" type="submit" disabled={fetcher.state !== 'idle'}>
                Request Sample
              </Button>
            </fetcher.Form>
          </>
        ) : (
          <div className="flex flex-col items-center">
            <div><b>Sample request sent</b></div>
            <div>Any reply will be sent to your email ({expert?.contactInfo?.email})</div>
            <Button color={'dark'} className="mt-10" type="submit" onClick={onClose}>
              Close
            </Button>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default RequestSampleModal;
