import {CarouselProductCard} from './ProductCards/CarouselProductCard';
import {Link} from '~/modules';
import {MergedProduct} from '../context/types';
import {StorefrontCard} from './ProductCards/StorefrontCard';
import {useExpertProductLink} from '~/modules/Common/hooks/useExpertLink';

export function ProductCard({
  product,
  className,
  containerClassName = '',
  onClick,
  theme = 'storefront',
  visibleByDefault = false,
}: {
  product: MergedProduct;
  label?: string;
  className?: string;
  onClick?: () => void;
  size?: 'small' | 'default';
  aspect?: 'default' | 'square';
  theme?: 'carousel' | 'storefront';
  visibleByDefault?: boolean;
  containerClassName?: string;
}) {
  const pdpLink = useExpertProductLink(product, false);

  return (
    <Link
      onClick={onClick}
      to={pdpLink}
      prefetch="none"
      className={containerClassName}
    >
      {theme === 'carousel' && (
        <CarouselProductCard product={product} className={className} />
      )}
      {theme === 'storefront' && (
        <StorefrontCard
          product={product}
          className={className}
          visibleByDefault={visibleByDefault}
        />
      )}
    </Link>
  );
}
