import {useCallback} from 'react';
import {useExpertLink} from '~/modules/Common/hooks/useExpertLink';

export const useGetCollections = () => {
  const action = useExpertLink('/my/api/collections');

  const getCollections = useCallback(async () => {
    const response = await fetch(action, {
      method: 'get',
    });
    const result = await response.json();
    return result?.collections || [];
  }, [action]);
  return getCollections;
};
