import {Media} from '~/gql/generated';
import {MergedProduct} from '../context/types';
import {useMemo} from 'react';

export const useProductImage = (product: MergedProduct) => {
  const firstImage = useMemo(() => {
    const image = product?.selectedVariant?.image;
    if (image) {
      return {
        src: image.url,
        alt: image.altText || '',
        createdAt: '',
        id: image.id,
      } as Media;
    }

    const id = product.selectedVariant?.media?.id;

    if (id) {
      return product.media.find((m) => m.id === id);
    } else {
      return product.media[0];
    }
  }, [
    product.media,
    product.selectedVariant?.image,
    product.selectedVariant?.media?.id,
  ]);
  return firstImage;
};
