import {FC, ReactNode, createContext, useMemo, useState} from 'react';

import {useMatches} from '@remix-run/react';

interface ExpertIdsContextProps {
  children: ReactNode;
}

export const ExpertIdsContext = createContext<{
  expertIds: string[];
  setIds: (ids: string[]) => void;
}>({
  expertIds: [],
  setIds: () => {},
});

export const ExpertIdsProvider: FC<ExpertIdsContextProps> = ({children}) => {
  const data = useMatches();
  const allIds = useMemo(
    () =>
      data.find(({handle}) => handle === 'my-expert')?.data?.expertProductIds ||
      [],
    [data],
  );
  const allExpertIds: string[] = useMemo(
    () => allIds.map(({id}) => id),
    [allIds],
  );
  const [additionalIds, setAdditionalIds] = useState<string[]>([]);
  const expertIds: string[] = useMemo(
    () => [...(allExpertIds || []), ...additionalIds],
    [allExpertIds, additionalIds],
  );
  const setIds = (ids: string[]) => {
    setAdditionalIds([...additionalIds, ...ids]);
  };

  return (
    <ExpertIdsContext.Provider
      value={{
        expertIds,
        setIds,
      }}
    >
      {children}
    </ExpertIdsContext.Provider>
  );
};
