import {AnalyticsEvent} from '~/modules/Common/hooks/analyticsEvent';
import {MergedProduct} from '~/modules/Product/context/types';
import {Product} from '@shopify/hydrogen/storefront-api-types';
import {useExpertLink} from '~/modules/Common/hooks/useExpertLink';
import {useRudderStack} from '~/modules/Common/hooks/useRudderstack';

export const useAddProduct = (expertName: string, hasProducts: boolean) => {
  const {trackEvent} = useRudderStack();
  const addLink = useExpertLink('/my/api/productmanage');

  const addProduct = async (product: Product | MergedProduct) => {
    try {
      const response = await fetch(addLink, {
        method: 'post',
        body: JSON.stringify({
          expertName,
          productId: product.id,
          action: 'add',
        }),
      });

      const result = await response.json();

      trackEvent(AnalyticsEvent.creatorProductAdded, {
        expertName,
        productId: product.id,
        shoppingType: product.shoppingType || '',
        affiliateSubType: product.affiliateSubType || '',
      });

      if (!hasProducts) {
        trackEvent(AnalyticsEvent.SubmitApplication, {
          items: [{creatorName: expertName || ''}],
        });
        if (typeof window.gtag === 'function') {
          window?.gtag?.('event', 'view_item', {
            items: [
              {
                creatorName: expertName || '',
              },
            ],
          });
        }
      }

      return result?.success || false;
    } catch (e) {
      console.error(e);
      return false;
    }
  };

  return addProduct;
};
