import {AffiliateSubType} from '~/gql/generated';
import {AnalyticsEvent} from '~/modules/Common/hooks/analyticsEvent';
import {MergedProduct} from '../context/types';
import {isProductIngested} from '../utils/productUtils';
import {useAddProduct} from '~/modules/Expert/hooks/useAddProduct';
import {useCallback} from 'react';
import {useExpert} from '~/modules/Common/hooks/useExpert';
import {useExpertLink} from '~/modules/Common/hooks/useExpertLink';
import {useImportProduct} from '~/modules/Expert/hooks/useImportProduct';
import {useMatches} from '@remix-run/react';
import {useRudderStack} from '~/modules/Common/hooks/useRudderstack';

interface CreateLinkProps {
  affiliateSubType: AffiliateSubType;
  hasProducts: boolean;
  source?: string;
  trackingData?: Record<string, string | number | boolean>;
}

export const useCreateLink = ({
  affiliateSubType,
  hasProducts,
  source,
  trackingData = {},
}: CreateLinkProps) => {
  const {expertName} = useExpert();
  const actionLink = useExpertLink('/my/api/generateLink');
  const addProduct = useAddProduct(expertName, hasProducts);
  const importProduct = useImportProduct(expertName);
  const [root] = useMatches();
  const {trackEvent} = useRudderStack();

  const submitLink = useCallback(
    async (
      product: MergedProduct,
    ): Promise<{
      success: boolean;
      error?: string;
      product: MergedProduct;
    }> => {
      const response = await fetch(actionLink, {
        method: 'post',
        body: JSON.stringify({
          productId: product.id,
          ...trackingData
        }),
      });
      const result = await response.json();
      const shortlinks = result?.result || [];
      const nextProduct = {...product, viewAllLinks: shortlinks};
      if (result?.success) {
        if (source === 'recommendation') {
          trackEvent(AnalyticsEvent.recommendationCreateLink, {
            productId: nextProduct.id,
            creatorName: expertName,
            creatorId: root.data?.userData?.expert?.id || '',
            ...trackingData,
          });
        }

        trackEvent(AnalyticsEvent.creatorCreateLiink, {
          productLink: shortlinks,
          productName: nextProduct.name,
          productId: nextProduct.id,
          affiliateSubType: nextProduct.affiliateSubType,
          shoppingType: nextProduct.shoppingType || '',
          creatorName: expertName,
          creatorId: root.data?.userData?.expert?.id || '',
          ...trackingData,
        });
      }
      return {
        success: result?.success,
        product: {...product, viewAllLinks: shortlinks},
        error: result?.success ? '' : result?.error || 'Failed to submit link',
      };
    },
    [
      actionLink,
      expertName,
      root.data?.userData?.expert?.id,
      source,
      trackEvent,
      trackingData,
    ],
  );

  const createLink = async (
    product?: MergedProduct,
    externalId?: string,
  ): Promise<{
    success: boolean;
    error?: string;
    product: MergedProduct | undefined;
    link: string;
  }> => {
    let isAdded = true;
    let nextProduct: MergedProduct | undefined = product;
    const shouldImportProduct =
      !isProductIngested(product!) && (product?.externalId || externalId);
    // let nextProduct = product;
    if (shouldImportProduct) {
      const result = await importProduct(
        product?.externalId || externalId || '',
        affiliateSubType,
      );
      if (result.success) {
        nextProduct = {...(product || {}), ...result?.product};
      } else {
        return {
          success: false,
          error: 'Failed to import product',
          product: nextProduct,
          link: '',
        };
      }
    }

    if (!nextProduct?.shortlink && nextProduct?.id) {
      isAdded = await addProduct(nextProduct);

      if (isAdded) {
        if (source === 'recommendation') {
          trackEvent(AnalyticsEvent.recommendationAddToStore, {
            productId: nextProduct.id,
            creatorName: expertName,
            creatorId: root.data?.userData?.expert?.id || '',
          });
        }
      }
    }

    if (isAdded && nextProduct?.id && !nextProduct?.shortlink) {
      return submitLink(nextProduct);
    } else {
      return {
        success: nextProduct?.shortlink ? true : false,
        error: nextProduct?.shortlink ? '' : 'Failed to create link',
        product: nextProduct,
        link: nextProduct?.shortlink || '',
      };
    }
  };

  return {
    createLink,
  };
};
