import {Footer} from '~/modules/Shell/components/Footer';
import {Header} from '~/modules/Shell/components/Header';
import {LogoTypes} from '~/modules/Appearance/types';
import {useExpert} from '~/modules/Common/hooks/useExpert';

export const Layout = ({children}: {children: React.ReactNode}) => {
  const {expertName, theme} = useExpert();

  let logo = theme?.store?.logo;
  const v2 = theme?.v2;
  if (v2) {
    logo = v2?.logoType === LogoTypes.image ? v2.logoPhoto : v2.profilePhoto;
  }

  let storeName = theme?.store?.name || expertName;
  if (v2) {
    storeName = v2?.logoType === LogoTypes.profile ? v2.name : '';
  }

  return (
    <>
      <div className="flex flex-col min-h-screen mb-8">
        <div className="">
          <a href="#mainContent" className="sr-only">
            Skip to content
          </a>
        </div>
        <Header title={storeName} logo={logo} logoType={v2?.logoType} />
        <main role="main" id="mainContent" className="flex-grow">
          {children}
        </main>
      </div>
      <Footer />
    </>
  );
};
