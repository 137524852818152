import {ProductCard, Section} from '~/modules';

import {MergedProduct} from '../context/types';

const mockProducts = new Array(12).fill('');

export function ProductSwimlane({
  title = 'Featured Products',
  products = mockProducts,
  count = 12,
  size,
  aspect,
  ...props
}: {
  title?: string;
  products?: MergedProduct[];
  count?: number;
  size?: 'small' | 'default';
  aspect?: 'default' | 'square';
  className?: string;
  headingClassName?: string;
}) {
  return (
    <Section heading={title} padding="y" {...props}>
      <div className="swimlane hiddenScroll md:pb-8 md:scroll-px-8 lg:scroll-px-12 md:px-8 lg:px-12">
        {products.map((product) => (
          <ProductCard
            product={product}
            key={product.id}
            className={`snap-start ${size === 'small' ? 'w-[126px]' : 'w-80'}`}
            size={size}
            aspect={aspect}
            theme="carousel"
          />
        ))}
      </div>
    </Section>
  );
}
