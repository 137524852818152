import {Await, useMatches} from '@remix-run/react';
import {Cart, CartLoading, Drawer} from '~/modules';

import {Suspense} from 'react';

export function CartDrawer({
  isOpen,
  isLoading,
  onClose,
}: {
  isOpen: boolean;
  isLoading?: boolean;
  onClose: () => void;
}) {
  const [root] = useMatches();

  return (
    <Drawer open={isOpen} onClose={onClose} heading="Cart" openFrom="right">
      <div className="grid">
        <Suspense fallback={<CartLoading />}>
          <Await resolve={root.data?.cartData}>
            {(data) => (
              <Cart
                layout="drawer"
                onClose={onClose}
                cart={data?.cart}
                isLoading={isLoading}
                cartProducts={data?.cartProducts}
              />
            )}
          </Await>
        </Suspense>
      </div>
    </Drawer>
  );
}
