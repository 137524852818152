import {FC} from 'react';

interface GrayTwitterProps {
  width?: number;
  height?: number;
}

export const GrayTwitter: FC<GrayTwitterProps> = ({
  width = 16,
  height = 16,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
        fill="black"
      />
      <path
        d="M3.07098 3.36475L6.89488 8.47758L3.04688 12.6346H3.91297L7.28197 8.99498L10.0039 12.6346H12.9511L8.91196 7.23419L12.4937 3.36475H11.6276L8.52503 6.71663L6.01814 3.36475H3.07098ZM4.34461 4.00265H5.69853L11.6773 11.9967H10.3234L4.34461 4.00265Z"
        fill="white"
      />
    </svg>
  );
};
