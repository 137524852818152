import {FC} from 'react';
import {LucideProps} from 'lucide-react';
import { Badge } from 'flowbite-react';
import {Text} from '~/modules'

interface IconBadge {
  icon: React.ForwardRefExoticComponent<LucideProps> | string
  label: string
  color: string
  className?: string
}

const IconBadge: FC<IconBadge> = ({label, color, icon: Icon, className = ''}: IconBadge) => (
  <Badge color={color} className={`${className} w-fit py-1`} theme={{
    root: {
      base: "flex h-fit items-center gap-1",
      color: {
        "gray": "bg-gray-100 text-gray-600 dark:bg-gray-700 dark:text-gray-300 group-hover:bg-gray-200 dark:group-hover:bg-gray-600",
        "purple": "bg-purple-100 text-purple-600 dark:bg-purple-200 dark:text-purple-900 group-hover:bg-purple-200 dark:group-hover:bg-purple-300",
        "green": "bg-green-100 text-green-600 dark:bg-green-200 dark:text-green-900 group-hover:bg-green-200 dark:group-hover:bg-green-300",
        "yellow": "bg-yellow-100 text-yellow-600 dark:bg-yellow-200 dark:text-yellow-900 group-hover:bg-yellow-200 dark:group-hover:bg-yellow-300"
      }
    }
  }}>
    <span className='flex gap-2 items-center'>
      {typeof Icon === 'string'
        ? <img src={Icon} height={12} width={12} />
        : <Icon size={16} />
      }
      <Text size='fine'>{label}</Text>
    </span>
  </Badge>
)

export default IconBadge;
