import {
  ChevronLeftIcon,
  ChevronRightIcon,
  SearchIcon,
} from '@heroicons/react/outline';
import {ReactNode, useEffect, useRef, useState} from 'react';

import {CartCount} from '~/modules/Cart/components/CartCount';
import {HeaderLogo} from './HeaderLogo';
import {Link} from '~/modules';
import {LogoTypes} from '~/modules/Appearance/types';
import {MenuItem} from '../types';
import {useExpertTheme} from '~/modules/Expert/hooks/useExpertTheme';

export function DesktopHeader({
  menu,
  openCart,
  title,
  logo,
  logoType,
  openSearch,
  isHome,
}: {
  isHome: boolean;
  openCart: () => void;
  menu?: MenuItem[];
  title: string | ReactNode;
  logo: string;
  logoType?: LogoTypes;
  isSearchOpen: boolean;
  openSearch?: () => void;
}) {
  const {isDefault} = useExpertTheme();
  const elRef = useRef(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  useEffect(() => {
    const isOverflowing = isOverflown(elRef?.current || {});
    setShowRightArrow(isOverflowing);
  }, [elRef]);

  const handleScroll = () => {
    const container = elRef.current;
    setShowLeftArrow(container.scrollLeft > 0);
    setShowRightArrow(
      container.scrollLeft < container.scrollWidth - container.clientWidth,
    );
  };

  const scrollToLeft = () => {
    const container = elRef.current;
    container.scrollTo({
      left: container.scrollLeft - container.clientWidth,
      behavior: 'smooth',
    });
  };

  const scrollToRight = () => {
    const container = elRef.current;
    container.scrollTo({
      left: container.scrollLeft + container.clientWidth,
      behavior: 'smooth',
    });
  };

  return (
    <>
      <div className="flex flex-row w-full justify-center sticky transition duration-300 backdrop-blur-lg z-40 top-0 bg-white">
        <header
          role="banner"
          className={
            'hidden lg:flex max-w-7xl w-full md:px-4 xl:px-0 py-6 text-primary items-center justify-between gap-8'
          }
        >
          <div className="flex gap-8 items-center max-w-full overflow-hidden">
            <HeaderLogo
              isHome={isHome}
              logoType={logoType}
              logo={logo}
              title={title}
            />
            {!isDefault && (
              <div className="overflow-hidden relative">
                <nav
                  className="flex gap-8 overflow-x-auto hiddenScroll"
                  ref={elRef}
                  onScroll={handleScroll}
                >
                  {/* Top level menu items */}
                  {(menu || []).map((item) => (
                    <Link
                      key={item.href}
                      to={item.href}
                      target={item.isExternal ? '_blank' : '_self'}
                      prefetch="intent"
                      className={'whitespace-nowrap'}
                      reloadDocument
                    >
                      {item.label}
                    </Link>
                  ))}
                </nav>
                {showLeftArrow && (
                  <button
                    className="absolute left-0 top-0 bottom-0 w-[34px] z-[40] flex flex-row justify-start"
                    style={{
                      background:
                        'linear-gradient(90deg, #FFFFFF 39.58%, rgba(255, 255, 255, 0) 100%)',
                    }}
                    onClick={scrollToLeft}
                  >
                    <ChevronLeftIcon width={23} height={23} />
                  </button>
                )}
                {showRightArrow && (
                  <button
                    className="absolute right-0 top-0 bottom-0 w-[34px] z-[40] flex flex-row justify-end"
                    style={{
                      background:
                        'linear-gradient(270deg, #FFFFFF 39.58%, rgba(255, 255, 255, 0) 100%)',
                    }}
                    onClick={scrollToRight}
                  >
                    <ChevronRightIcon width={23} height={23} />
                  </button>
                )}
              </div>
            )}
          </div>
          <div className="flex items-center gap-4">
            <button type="button" onClick={openSearch}>
              <SearchIcon width={20} height={20} />
            </button>
            <CartCount openCart={openCart} />
          </div>
        </header>
      </div>
    </>
  );
}

const isOverflown = ({clientWidth, scrollWidth}) => {
  return scrollWidth > clientWidth;
};
