import {FC, ReactNode} from 'react';

import {Heading} from '~/modules/Common/components/Heading';
import {Link} from '~/modules/Common/components/Link';
import {LogoTypes} from '~/modules/Appearance/types';
import {Text} from '~/modules/Common/components/Text';

interface HeaderLogoProps {
  title: string | ReactNode;
  logo: string;
  logoType?: LogoTypes;
  isHome: boolean;
}

export const HeaderLogo: FC<HeaderLogoProps> = ({
  title,
  logo,
  logoType,
  isHome,
}) => (
  <Link
    className="flex flex-none overflow-hidden items-center self-stretch leading-[3rem] py-2 md:py-0"
    to="/"
  >
    {!logo && logoType !== LogoTypes.profile && (
      <Heading
        className="font-bold text-center whitespace-nowrap overflow-hidden text-lg font-sans"
        as={isHome ? 'h1' : 'h2'}
      >
        {title}
      </Heading>
    )}
    {logo && (
      <img
        src={logo}
        alt={title || 'Logo'}
        className={`block flex-none max-w-[150px] max-h-[35px] md:max-h-[48px] ${
          logoType === LogoTypes.profile
            ? 'rounded-full overflow-hidden aspect-square'
            : 'm-auto'
        }`}
      />
    )}
    {logoType === LogoTypes.profile && title && (
      <Text
        size="sm"
        as="span"
        className="pl-2 md:text-base font-headline font-sans"
      >
        {title}
      </Text>
    )}
  </Link>
);
