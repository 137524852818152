import {Amazon} from '~/icons/Amazon';
import {FC} from 'react';
import {Text} from './components/Text';

interface AmazonLogoProps {
  className?: string;
}

export const AmazonLogo: FC<AmazonLogoProps> = ({className = ''}) => (
  <Text size="sm" as="p" className={`center-row gap-1 ${className}`}>
    <Amazon />
    <span>amazon.com</span>
  </Text>
);
