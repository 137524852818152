import {FC, useRef, useState} from 'react';
import {SearchIcon, XIcon} from '@heroicons/react/outline';
import {useDebounce, useKey} from 'react-use';

import {AnalyticsEvent} from '~/modules/Common/hooks/analyticsEvent';
import {PortalSpinner} from '~/modules/Common/components/PortalSpinner';
import {SearchAutocompleteResultsList} from './SearchAutocompleteResultsList';
import {Text} from '~/modules/Common/components/Text';
import {TextInput} from 'flowbite-react';
import {useExpert} from '~/modules/Common/hooks/useExpert';
import {useExpertLink} from '~/modules/Common/hooks/useExpertLink';
import {useRudderStack} from '~/modules/Common/hooks/useRudderstack';

interface SearchAutocompleteProps {
  onCancelClick: () => void;
  onItemClick: () => void;
}

export const SearchAutocomplete: FC<SearchAutocompleteProps> = ({
  onCancelClick,
  onItemClick,
}) => {
  const [term, setTerm] = useState('');
  const prevTerm = useRef(term);
  const {expertName} = useExpert();
  const searchLink = useExpertLink('/search-input');
  const searchPageLink = useExpertLink(`/search/${term}`);
  const [isLoading, setIsLoading] = useState(false);
  const {trackEvent} = useRudderStack();
  const [searchResult, setSearchResult] = useState(null);
  const [shopifyProducts, setShopifyProducts] = useState([]);
  const onSearchItemClick = (opts) => {
    trackEvent(AnalyticsEvent.shopperSearchItemClick, {...opts, expertName});
    onItemClick();
  };

  const onXClick = () => {
    setTerm('');
    prevTerm.current = '';
  };

  const onSearchClick = () => {
    if (term.length > 0) {
      // redirecting to search results page with term
      trackEvent(AnalyticsEvent.shopperSearchTermSubmitted, {term, expertName});
      window.location.href = searchPageLink;
    }
  };

  useKey('Enter', () => onSearchClick(), {}, [term]);
  useKey('Escape', () => onCancelClick());

  useDebounce(
    async () => {
      if (prevTerm.current !== term && term.length > 0) {
        prevTerm.current = term;
        setIsLoading(true);
        const response = await fetch(searchLink, {
          method: 'post',
          body: JSON.stringify({
            term,
          }),
        });
        const result = await response.json();
        if (result) {
          setSearchResult(result.result);
          setShopifyProducts(result.shopifyProducts?.nodes || []);
        }
        setIsLoading(false);
        trackEvent(AnalyticsEvent.shopperSearchTermSubmitted, {
          term,
          expertName,
        });
      }
    },
    300,
    [term, searchLink, expertName],
  );

  return (
    <div className="bg-white relative md:h-[72px] flex flex-col justify-center">
      <div className="px-2 py-2 center-row justify-center">
        <div className="relative w-full max-w-[588px] ">
          <div className="center-row gap-1">
            <TextInput
              type="search"
              autoFocus={true}
              onChange={(e) => {
                setTerm(e.target.value);
              }}
              value={term}
              rightIcon={() => (
                <div className="center-row gap-1 pr-2">
                  {term.length > 0 && (
                    <button type="button" onClick={onXClick}>
                      <XIcon width={18} height={18} />
                    </button>
                  )}
                  {!isLoading ? (
                    <span className="rounded-full bg-expertPrimary p-1">
                      <SearchIcon
                        onClick={onSearchClick}
                        width={16}
                        height={16}
                        stroke="#fff"
                      />
                    </span>
                  ) : (
                    <PortalSpinner size="sm" />
                  )}
                </div>
              )}
              theme={{
                base: 'w-full',
                field: {
                  input: {base: 'bg-white pr-12 w-full !text-base'},
                },
              }}
              placeholder="What are you looking for?"
            />
            <button className="md:hidden" onClick={onCancelClick}>
              <Text size="sm" className="underline">
                <span>Cancel</span>
              </Text>
            </button>
          </div>
          <div className="absolute w-full end-full left-0 z-[10000]">
            <SearchAutocompleteResultsList
              result={searchResult || null}
              shopifyProducts={shopifyProducts || []}
              term={term}
              onItemClick={onSearchItemClick}
              productLimit={10}
            />
          </div>
        </div>
      </div>
      <button
        className="hidden md:block absolute top-[28px] right-[16px]"
        onClick={onCancelClick}
        title="Close Search"
        aria-label="Close Search"
      >
        <XIcon height={20} width={20} />
      </button>
    </div>
  );
};
