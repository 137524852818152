import {formatDiscountValue, formatPrice} from '../utils/productUtils';

import {Discount} from '~/gql/generated';
import {Maybe} from 'graphql/jsutils/Maybe';
import {MoneyV2} from '@shopify/hydrogen/storefront-api-types';
import {TagIcon} from '@heroicons/react/solid';
import {Text} from '~/modules/Common/components/Text';
import {useProduct} from '../hooks/useProduct';
import {useProductPrice} from '../hooks/useProductPrice';

export const ProductPrice = () => {
  const product = useProduct();
  const {isOnSale, compareAtPrice, price, discount} = useProductPrice();
  return (
    <>
      <Text className="flex items-center gap-2 pt-1 overflow-hidden">
        <PriceDisplay
          isOnSale={isOnSale as boolean}
          compareAtPrice={compareAtPrice!}
          price={price}
          discount={discount}
          coupon={{ type: product?.couponType as string, value: product?.couponVal as string}}
        />
      </Text>
      <DiscountCopy discount={discount} brandName={product?.brandName} />
    </>
  );
};

function DiscountCopy({
  discount,
  brandName = '',
}: {
  discount: Maybe<Discount>;
  brandName?: string;
}) {
  const value = parseFloat(discount?.amountValue || '');
  if (!discount?.amountValue || value <= 0) return null;

  const formatted =
    discount?.amountType === 'PERCENT'
      ? `${formatDiscountValue(discount?.amountValue)}%`
      : formatPrice(discount.amountValue, 2, true);

  return (
    <Text className="text-feta700 text-xs center-row gap-1" size="copy">
      <TagIcon width={14} />{' '}
      {!discount.displayStrikethrough ? (
        <>Discount automatically applied at checkout!</>
      ) : discount?.minimumOrderValue &&
        parseFloat(discount?.minimumOrderValue) > 0 ? (
        `Save ${formatted} when you spend ${formatPrice(
          discount.minimumOrderValue,
          2,
          true,
        )} on ${brandName} products`
      ) : (
        `${formatted} exclusive discount applies`
      )}
    </Text>
  );
}

interface PriceDisplayProps {
  isOnSale: boolean;
  compareAtPrice?: {amount: number; currencyCode: string};
  price: MoneyV2 | {amount: string; currencyCode: string};
  size?: string;
  discount?: Maybe<Discount>;
  className?: string;
  useColor?: boolean;
  priceFontClass?: string;
  coupon: { value: string, type: string},
  showDeal?: boolean
}
export const PriceDisplay = ({
  isOnSale,
  compareAtPrice,
  price,
  size = 'text-2xl',
  discount,
  className = '',
  useColor = true,
  priceFontClass = '',
  coupon,
  showDeal = true,
}: PriceDisplayProps) => {
  const hideDifferentPrice =
    !discount?.displayStrikethrough || discount?.minimumOrderValue;
  const visiblePrice = hideDifferentPrice ? compareAtPrice : price;

  const isDiscount = isOnSale && discount && Number(discount?.amountValue) > 0;

  const priceFloat = parseFloat(visiblePrice?.amount!);
  const isPriceZero = priceFloat === 0;
  const formattedPrice = formatPrice(priceFloat);

  return (
    <p className={`items-baseline gap-1 flex flex-wrap ${className}`}>
      <span className={`${isPriceZero ? 'text-sm' : (size || 'text-lg')} ${priceFontClass || 'font-bold'}`}>
        {isPriceZero ? 'See Price on Amazon' : formattedPrice}
      </span>

      {isOnSale && discount?.displayStrikethrough && !hideDifferentPrice && (
        <span
          className={`line-through decoration-1 px-1 ${
            useColor ? 'text-codGray400' : 'opacity-80'
          } ${size || 'text-sm'} `}
        >
          {formatPrice(parseFloat(compareAtPrice?.amount!))}
        </span>
      )}
      {
        showDeal && (
          <span>
          {
            isDiscount && (
              <span className={`text-red-500 text-sm`}>
                {Math.round(+discount.amountValue)}% off
              </span>
            )
          }
          {
            isDiscount && coupon.type && Number(coupon?.value) > 0 && (
              <span className='text-red-500 text-sm'>
                &nbsp; + &nbsp;
              </span>
            )
          }
          {
            coupon.type && Number(coupon?.value) > 0 && (
              <span className='text-red-500 text-sm'>
                {coupon.type?.toLowerCase() === 'percent' ? `${Math.round(+coupon.value)}% ` : `$${coupon.value}`} coupon
              </span>
            )
          }
          </span>
        )
      }
    </p>
  );
};
