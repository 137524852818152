import {FC} from 'react';

interface GrayTiktokProps {
  width?: number;
  height?: number;
}

export const GrayTiktok: FC<GrayTiktokProps> = ({width = 16, height = 16}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="8" fill="#111928" />
      <path
        d="M10.1982 2.3335H8.35673V9.82624C8.35673 10.719 7.64848 11.4523 6.76708 11.4523C5.88568 11.4523 5.17742 10.719 5.17742 9.82624C5.17742 8.94945 5.86995 8.23203 6.71987 8.20016V6.31901C4.8469 6.35088 3.33594 7.89727 3.33594 9.82624C3.33594 11.7712 4.87838 13.3335 6.78283 13.3335C8.68725 13.3335 10.2297 11.7552 10.2297 9.82624V5.98421C10.9222 6.49437 11.7721 6.79727 12.6693 6.81322V4.93205C11.2842 4.88422 10.1982 3.73639 10.1982 2.3335Z"
        fill="white"
      />
    </svg>
  );
};
