import {FC} from 'react';

interface WaywardShortProps {}

export const WaywardShort: FC<WaywardShortProps> = () => {
  return (
    <svg
      width="75"
      height="20"
      viewBox="0 0 75 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3970_13619)">
        <mask
          id="mask0_3970_13619"
          style={{maskType: 'luminance'}}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="75"
          height="20"
        >
          <path d="M75 0H0V19.5293H75V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_3970_13619)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.0111 5.48311C22.1869 4.75225 21.0591 4.38647 19.627 4.38647C18.7891 4.38647 18.0331 4.53836 17.3577 4.84143C16.9585 5.01282 16.6024 5.2295 16.2885 5.49145L15.6772 8.04838H17.4996C17.5944 7.55165 17.8373 7.1796 18.229 6.93087C18.6206 6.66892 19.0798 6.53794 19.6065 6.53794C20.1871 6.53794 20.6736 6.70375 21.0652 7.03467C21.4568 7.3656 21.6527 7.88326 21.6527 8.58624V8.83428H19.0996C17.6408 8.83428 16.5464 9.13106 15.8171 9.72399C15.0877 10.3169 14.7227 11.0896 14.7227 12.0413C14.7227 12.607 14.8578 13.1239 15.1279 13.5928C15.3981 14.0617 15.8103 14.4414 16.3642 14.7306C16.9183 15.0064 17.6272 15.1444 18.4916 15.1444C19.3561 15.1444 20.0111 14.9654 20.5385 14.6065C21.0789 14.2477 21.4978 13.7928 21.7946 13.241L22.0375 14.8964H24.2461V8.58555C24.2461 7.24716 23.834 6.21326 23.0098 5.4817L23.0111 5.48311ZM20.7343 12.3318C20.329 12.8285 19.7553 13.0766 19.0122 13.0766C18.5257 13.0766 18.1341 12.9665 17.8373 12.7456C17.5398 12.5248 17.3918 12.2147 17.3918 11.8148C17.3918 11.4421 17.5405 11.132 17.8373 10.884C18.1341 10.622 18.6274 10.4911 19.3165 10.4911H21.5251C21.4173 11.2219 21.1539 11.8357 20.735 12.3325L20.7343 12.3318Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.93794 14.8983L0 4.63507H2.57359L4.31616 12.0223L6.34256 4.63507H9.21979L11.2455 12.0223L13.0085 4.63507H15.5821L12.6237 14.8983H9.92866L7.78084 7.22197L5.63298 14.8983H2.93794Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M28.6119 14.2156L26.2818 19.45H29.0376L35.4204 4.63513H32.6647L29.7267 11.8566L26.9102 4.63513H24.0938L28.0039 14.2156H28.6119Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M72.405 0V6.0419C72.0539 5.51795 71.5943 5.11107 71.0278 4.82125C70.4598 4.53141 69.7981 4.3865 69.0414 4.3865C68.0956 4.3865 67.252 4.62129 66.5086 5.0902C65.7658 5.54512 65.1782 6.17985 64.7459 6.99361C64.3136 7.79341 64.0977 8.71098 64.0977 9.74561C64.0977 10.7802 64.3136 11.7048 64.7459 12.5185C65.1782 13.3323 65.7658 13.974 66.5086 14.4428C67.252 14.9117 68.0956 15.1465 69.0414 15.1465C69.8927 15.1465 70.5811 14.9738 71.108 14.6289C71.6483 14.284 72.0811 13.8841 72.405 13.4291L72.6887 14.8985H74.9989V0H72.405ZM71.6354 11.98C71.108 12.5457 70.4263 12.8285 69.5884 12.8285C68.7505 12.8285 68.0827 12.5387 67.5424 11.9598C67.0021 11.3801 66.7317 10.6423 66.7317 9.74561C66.7317 8.84893 67.0021 8.1181 67.5424 7.55234C68.0827 6.98663 68.7644 6.70376 69.5884 6.70376C70.4129 6.70376 71.108 6.99361 71.6354 7.57259C72.1757 8.13829 72.4461 8.86912 72.4461 9.7658C72.4461 10.6625 72.1757 11.4003 71.6354 11.98Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M58.3516 14.8977V4.63438H60.6622L60.9049 6.55869C61.2699 5.89683 61.7624 5.3722 62.3839 4.9862C63.0188 4.58631 63.7625 4.38636 64.6133 4.38636L64.6406 4.32922L63.8839 7.17947C63.3159 7.17947 62.81 7.26937 62.3643 7.44839C61.9186 7.62747 61.5675 7.9382 61.311 8.37922C61.0678 8.82025 60.946 9.43474 60.946 10.2206V14.8969H58.3525L58.3516 14.8977Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M55.8514 5.48311C55.0274 4.75225 53.8995 4.38647 52.4674 4.38647C51.6295 4.38647 50.8737 4.53836 50.1982 4.84143C49.9928 4.92989 49.7984 5.03024 49.615 5.14238L48.7417 8.04838H50.3392C50.4342 7.55165 50.6769 7.1796 51.0686 6.93087C51.4604 6.66892 51.9194 6.53794 52.4463 6.53794C53.0268 6.53794 53.5135 6.70375 53.9053 7.03467C54.2965 7.3656 54.4924 7.88326 54.4924 8.58624V8.83428H51.9395C50.4806 8.83428 49.3861 9.13106 48.6567 9.72399C47.9277 10.3169 47.5625 11.0896 47.5625 12.0413C47.5625 12.607 47.6976 13.1239 47.9678 13.5928C48.2377 14.0617 48.65 14.4414 49.2041 14.7306C49.7583 15.0064 50.4672 15.1444 51.3314 15.1444C52.196 15.1444 52.851 14.9654 53.3783 14.6065C53.9186 14.2477 54.3376 13.7928 54.6342 13.241L54.8774 14.8964H57.0858V8.58555C57.0858 7.24716 56.6736 6.21326 55.8495 5.4817L55.8514 5.48311ZM53.643 12.3318C53.2379 12.8285 52.6642 13.0766 51.9209 13.0766C51.4346 13.0766 51.0428 12.9665 50.7462 12.7456C50.449 12.5248 50.3005 12.2147 50.3005 11.8148C50.3005 11.4421 50.449 11.132 50.7462 10.8833C51.0433 10.6214 51.5363 10.4904 52.2252 10.4904H54.4336C54.3261 11.2212 54.0629 11.835 53.6439 12.3318H53.643Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M34.5039 9.68831L35.9954 14.8983H38.6904L40.8383 7.22197L42.9861 14.8983H45.6812L48.6395 4.63507H46.066L44.3036 12.0223L42.2773 4.63507H39.4L37.3736 12.0223L35.9817 6.12188L34.5039 9.68831Z"
            fill="black"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_3970_13619">
          <rect width="75" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
