import {FC, useEffect, useState} from 'react';
import {Modal, ToggleSwitch} from 'flowbite-react';
import { ExternalLink, Plus } from 'lucide-react';
import { Heading, Button, Text } from '~/modules';
import { useExpertLink } from '~/modules/Common/hooks/useExpertLink';
import { PortalSpinner } from '~/modules/Common/components/PortalSpinner';

interface ViewProductLinksModalProps {
  closeModal: () => void
  productId: string
  openCreateAffiliateModal: () => void
  brandType: string
}

interface ProductLinks {
  id: string
  slug: string
  url: string
  shortlinkId: string
  linkType: string
  primaryTrackingId: string
	subTrackingId: string
	amzAssociateId: string
	sourceName: string
  shortlinkUrl: string
}

const ViewProductLinksModal: FC<ViewProductLinksModalProps> = (props: ViewProductLinksModalProps) => {
  const {closeModal, productId, openCreateAffiliateModal, brandType} = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [productLinks, setProductLinks] = useState<ProductLinks[]>([]);
  const actionLink = useExpertLink('/my/api/getProductLinks');
  const [linkCopied, setLinkCopied] = useState<string | null>(null);
  const [viewShortLinks, setViewShortLink] = useState<boolean>(true);

  const handleModalVisibility = (event) => {
    closeModal(event);
  }

  useEffect(() => {
    const getProductLinks = async () => {
      try {
        setIsLoading(true);
        const response: any = await fetch(actionLink, {
          method: 'post',
          body: JSON.stringify({
            productId
          }),
        });
        const parsedResponse = await response.json();
        setProductLinks(parsedResponse.result);
        setIsLoading(false);
      } catch(err) {
        setProductLinks([]);
        setIsLoading(false);
      }
    }
    getProductLinks();
  }, []);

  const getDisplayName = (item: ProductLinks) => {
    if(item.linkType.toLowerCase() === 'pdp') return 'Wayward Standard PDP';
    else if(item.linkType.toLowerCase() === 'amazon' && !item.primaryTrackingId) return 'Direct to Amazon Link';
    return item.sourceName || 'Internal Display Name';
  }

  const handleCopyLink = (link: string) => {
    if (link) {
      navigator.clipboard.writeText(link);
      setLinkCopied(link);
    }
  };

  useEffect(() => {
    let timerId: ReturnType<typeof setTimeout>;
    if (linkCopied) {
      timerId = setTimeout(() => {
        setLinkCopied(null);
      }, 2000);
    }
    return () => {
      if (timerId) clearTimeout(timerId);
    }
  }, [linkCopied]);

  const handleCreateLink = (event: any) => {
    closeModal(event);
    openCreateAffiliateModal();
  }

  const handleViewLinks = () => {
    setViewShortLink((prev) => !prev);
  }

  return (
    <Modal
      show
      size={'lg'}
      className="h-screen z-[200]"
      onClose={handleModalVisibility}
    >
      <Modal.Header className='text-center'>
        <Heading size="headlineSm" as="span">
          All Links
        </Heading>
      </Modal.Header>
      <Modal.Body className='!p-0 !py-6 h-[500px] overflow-auto'>
        {
          brandType.toLowerCase() == 'amazon' && (
            <div className='pb-6 border-b border-inherit'>
              <Button
                color='light'
                size="sm"
                className="flex-auto whitespace-nowrap !px-0.5 !py-1 mx-3 gap-1 items-center"
                style={{ width: 'calc(100% - 24px)'}}
                onClick={handleCreateLink}
              >
                <Plus size={12} />
                Create New Link
              </Button>
            </div>
          )
        }
        {isLoading && <div className='flex justify-center items-center h-[90%]'><PortalSpinner /></div>}
        {!isLoading && (
          <div className={`flex gap-2 justify-end items-center m-4 ${brandType.toLowerCase() === 'wayward' ? 'mb-0 mt-0' : ''}`}>
            <Text size="sm" internal>View Short Links</Text>
            <ToggleSwitch
              checked={viewShortLinks}
              label=''
              onChange={handleViewLinks}
              className='gap-0'
              theme={{
                root: {
                  base: 'flex flex-col gap-1 items-center',
                  label: 'text-fine text-black font-medium pt-1 md:pt-0',
                },
              }}
            />
          </div>
        )}
        {
          !isLoading && productLinks?.map((item) => (
            <div key={item.id} className='border-b border-inherit'>
              <div style={{ width: 'calc(100% - 24px)'}} className='flex flex-col gap-3 mx-3 my-3'>
                <div className='flex flex-col justify-center'>
                  <Text size='subtext' className='font-bold'>
                    {getDisplayName(item)}
                  </Text>
                  {
                    item.linkType.toLowerCase() === 'pdp' && (
                      <Text className='text-xs text-gray-500'>
                        {item.primaryTrackingId || 'default'} | {item.subTrackingId || 'default'}
                      </Text>
                    )
                  }
                  {
                    item.linkType.toLowerCase() !== 'pdp' && (
                      <Text className='text-xs text-gray-500'>
                        {item.amzAssociateId} | {item.primaryTrackingId || 'default'} | {item.subTrackingId || 'default'}
                      </Text>
                    )
                  }
                </div>
                <div className='flex gap-2 items-center'>
                  <Text className='text-xs truncate !whitespace-nowrap'>
                    {viewShortLinks ? item.shortlinkUrl : item.url}
                  </Text>
                  <a href={viewShortLinks ? item.shortlinkUrl : item.url} target='_blank'><ExternalLink size={16} /></a>
                </div>
                <Button
                  id={item.url}
                  className="!px-2 !py-1 w-fit text-xs"
                  onClick={() => handleCopyLink(viewShortLinks ? item.shortlinkUrl : item.url)}
                >
                  {(viewShortLinks ? item.shortlinkUrl : item.url) === linkCopied ? `Link Copied!` : `Copy Link`}
                </Button>
              </div>
            </div>
          ))
        }
      </Modal.Body>
    </Modal>
  )
}

export default ViewProductLinksModal;
