import {CloudImage} from '~/modules/Common/components/CloudImage';
import {FC} from 'react';
import {Media} from '~/gql/generated';
import {Media as ShopifyMedia} from '@shopify/hydrogen/storefront-api-types';
import {getMediaProps} from '~/modules/Common/util';

interface GalleryThumbnailsProps {
  mergedMedia: (Media | ShopifyMedia)[];
  onThumbClick: (index: number) => void;
}

export const GalleryThumbnails: FC<GalleryThumbnailsProps> = ({
  mergedMedia,
  onThumbClick,
}) => (
  <ol className="swimlane w-screen hiddenScroll gap-2 justify-center !pb-2">
    {mergedMedia.map((med, index) => {
      const {isVideo, src, alt} = getMediaProps(med);

      return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <li
          key={src}
          className="w-10 h-10 mx-0 my-2 rounded-md overflow-hidden bg-codGray200 hover:bg-codGray400 cursor-pointer"
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
          role="button"
          onClick={() => onThumbClick(index)}
          tabIndex={0}
        >
          {isVideo ? (
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <video className="w-full h-full object-cover">
              <source src={`${src}#t=0.001`} type="video/mp4" />
            </video>
          ) : (
            <CloudImage
              src={src}
              alt={alt}
              className="w-full h-full object-cover fadeIn"
              height={40}
              width={40}
              visibleByDefault={true}
            />
          )}
        </li>
      );
    })}
  </ol>
);
