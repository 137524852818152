import {FC} from 'react';

interface GrayThreadsProps {
  width?: number;
  height?: number;
}

export const GrayThreads: FC<GrayThreadsProps> = ({
  width = 16,
  height = 16,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="8" fill="black" />
      <path
        d="M10.605 7.60247C10.5581 7.58007 10.5105 7.55842 10.4623 7.53772C10.3784 5.99005 9.53264 5.10402 8.11278 5.09499C8.10638 5.09499 8.09998 5.09499 8.09358 5.09499C7.24427 5.09499 6.53801 5.45753 6.10318 6.11711L6.88417 6.65282C7.20888 6.16002 7.71862 6.05499 8.09396 6.05499C8.09829 6.05499 8.10262 6.05499 8.10695 6.05499C8.57452 6.058 8.92728 6.19391 9.15561 6.45894C9.32182 6.65188 9.43288 6.91861 9.48803 7.25518C9.07354 7.18478 8.62516 7.16313 8.14591 7.19061C6.79589 7.26835 5.92794 8.05574 5.98629 9.14977C6.01584 9.70468 6.29236 10.1822 6.76464 10.494C7.16408 10.7577 7.67852 10.8866 8.21311 10.8574C8.91918 10.8187 9.47297 10.5493 9.85942 10.0569C10.1529 9.68285 10.3385 9.19814 10.4204 8.58732C10.7569 8.79042 11.0061 9.05753 11.1439 9.37885C11.3781 9.92492 11.3918 10.8222 10.6596 11.5537C10.0181 12.1947 9.2469 12.4719 8.08154 12.4804C6.78893 12.4708 5.81123 12.0563 5.17556 11.2482C4.58036 10.4915 4.27278 9.39861 4.2613 7.99984C4.27278 6.60106 4.58036 5.50798 5.17556 4.75146C5.81123 3.94336 6.78893 3.52887 8.08154 3.51927C9.38356 3.52887 10.3784 3.94544 11.0383 4.75748C11.3619 5.1556 11.6059 5.65631 11.7668 6.24021L12.682 5.99607C12.487 5.27739 12.1804 4.65809 11.7629 4.14459C10.9167 3.10365 9.67928 2.57019 8.08493 2.55908H8.07852C6.48737 2.57019 5.26384 3.10553 4.44182 4.15042C3.71034 5.08031 3.33311 6.37405 3.32031 7.99588V7.99965V8.00341C3.33292 9.62525 3.71034 10.9192 4.44182 11.8491C5.26384 12.894 6.48737 13.4295 8.07852 13.4404H8.08493C9.49951 13.4306 10.4966 13.0602 11.3181 12.2395C12.3927 11.1658 12.3605 9.81988 12.0062 8.99372C11.7521 8.40134 11.2676 7.92021 10.605 7.60247ZM8.16267 9.89875C7.57104 9.93207 6.95627 9.66647 6.92596 9.09762C6.90356 8.67579 7.2262 8.2052 8.199 8.14911C8.31043 8.14271 8.4198 8.13951 8.52709 8.13951C8.88041 8.13951 9.21095 8.17377 9.51156 8.23946C9.39937 9.63974 8.74187 9.86694 8.16267 9.89875Z"
        fill="white"
      />
    </svg>
  );
};
