import {AffiliateSubType} from '~/gql/generated';
import {AnalyticsEvent} from '~/modules/Common/hooks/analyticsEvent';
import {useExpertLink} from '~/modules/Common/hooks/useExpertLink';
import {useRudderStack} from '~/modules/Common/hooks/useRudderstack';

export const useImportProduct = (expertName: string) => {
  const {trackEvent} = useRudderStack();
  const importLink = useExpertLink('/my/api/productmanage');

  const importProduct = async (
    externalProductId: string,
    affiliateSubType: AffiliateSubType,
  ) => {
    try {
      const vars = {
        affiliateSubType,
        externalProductId,
      };
      const response = await fetch(importLink, {
        method: 'post',
        body: JSON.stringify({
          ...vars,
          action: 'import',
        }),
      });
      const result = await response.json();

      trackEvent(AnalyticsEvent.creatorProductImported, {
        expertName,
        ...vars,
      });

      return result || false;
    } catch (e) {
      console.error(e);
      return false;
    }
  };

  return importProduct;
};
