import {FC, PropsWithChildren, useEffect} from 'react';

import useBreakpoints from '../hooks/useBreakpoint';

interface BottomDrawerProps {
  onClose?: () => void;
  hasBackdrop?: boolean;
  className?: string;
  containerClassName?: string;
  contentClassName?: string;
}

export const BottomDrawer: FC<PropsWithChildren<BottomDrawerProps>> = ({
  children,
  hasBackdrop = true,
  onClose,
  className = '',
  containerClassName = '',
  contentClassName = '',
}) => {
  const {isMd} = useBreakpoints();
  useEffect(() => {
    if (!isMd) {
      document.body.classList.add(`overflow-hidden`);
    }
    return () => {
      document.body.classList.remove(`overflow-hidden`);
    };
  }, [isMd]);

  return (
    <div
      className={`fixed bottom-0 left-0 right-0 z-[100] w-full p-4 overflow-y-auto transition-transform transform-none ${className}`}
      tabIndex={-1}
      aria-labelledby="drawer-bottom-label"
    >
      <div
        className={`${
          hasBackdrop ? 'inset-0 bg-codGray ' : 'left-0 right-0 w-full bottom-0'
        } fixed  bg-opacity-25 transition-opacity justify-end flex flex-col ${containerClassName}`}
        aria-hidden="true"
      >
        <div
          className={`p-2 bg-white rounded-t-md relative ${contentClassName}`}
        >
          {children}
          {onClose && (
            <button
              className="absolute top-2 right-2 text-codGray hover:text-codGray focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              onClick={onClose}
            >
              <span className="sr-only">Close panel</span>
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
