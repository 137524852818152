import {useExpertLink} from '~/modules/Common/hooks/useExpertLink';

interface CollectionFields {
  collectionId: string;
  name: string;
  description: string;
  bannerImageUrl: string;
  displayInNavigation: boolean;
  displayInHomePage: boolean;
  productIds: string[];
}

export const useCreateCollection = () => {
  const action = useExpertLink('/my/api/collections');

  const createCollection = async (fields: CollectionFields) => {
    const response = await fetch(action, {
      method: 'post',
      body: JSON.stringify({
        fields,
        action: 'create',
      }),
    });
    const result = await response.json();
    return result;
  };
  return createCollection;
};
