import {FC} from 'react';
import { ExternalLink } from 'lucide-react';
import { Tooltip } from 'flowbite-react';

import IconBadge from '~/modules/Common/components/IconBadge';

const BrandTypeConfig: {[key: string]: any} = {
  'AMAZON': {
    label: 'Amazon Direct',
    icon: '/images/amazonIcon.svg',
    color: 'yellow',
    tooltipText: 'Directly integrated with Wayward offering you affiliate commissions on-top of Amazon Associates commissions.',
  },
  'WAYWARD': {
    label: 'Wayward Exclusive',
    icon: '/images/waywardIcon.svg',
    color: 'purple',
    tooltipText: 'Directly integrated with Wayward, allows for purchase of their products through your own Wayward checkout process.'
  },
  AFFILIATE: {
    label: 'Affiliate',
    icon: ExternalLink,
    color: 'gray',
    tooltipText: 'Standard affiliate relationship, brand is not directly integrated with Wayward.'
  }
}

export enum BrandType {
  AMAZON = 'AMAZON',
  WAYWARD = 'WAYWARD',
  AFFILIATE = 'AFFILIATE'
}

interface ProductBrandBadgeProps {
  brandType: BrandType | string
}

const ProductBrandBadge: FC<ProductBrandBadgeProps> = ({brandType}: ProductBrandBadgeProps) => {
  const brandConfig = BrandTypeConfig[brandType];
  return (
    brandConfig
    ? (
      <Tooltip
        content={brandConfig.tooltipText}
        placement='bottom'
        theme={{
          "base": "absolute inline-block z-50 rounded-lg py-2 px-3 text-xs font-medium shadow-sm w-96 opacity-80",
          "style": {
            "dark": "bg-gray-900 text-white dark:bg-gray-700",
          }
        }}
      >
        <IconBadge icon={brandConfig.icon} color={brandConfig.color} label={brandConfig.label} />
      </Tooltip>
    )
    : null
  );
}

export default ProductBrandBadge;
