import {FC, useMemo} from 'react';

import {AffiliateSubType} from '~/gql/generated';
import {AmazonImage} from '~/modules/Common/components/AmazonImage';
import {CloudImage} from '~/modules/Common/components/CloudImage';
import {MergedProduct} from '../context/types';
import {useProductImage} from '../hooks/useProductImage';

interface ProductImageProps {
  className?: string;
  product: MergedProduct;
  visibleByDefault?: boolean;
}

export const ProductImage: FC<ProductImageProps> = ({
  className = '',
  product,
  visibleByDefault = false,
}) => {
  const image = useProductImage(product);
  const isAmazon = product?.brandType === AffiliateSubType.Amazon;
  return isAmazon ? (
    <div className="absolute top-0 left-0 right-0 bottom-0 w-full bg-white center-row justify-center">
      <AmazonImage
        className={`max-w-full max-h-full w-auto block`}
        width={350}
        height={350}
        src={product.media?.[0]?.src || '/images/no-image.png'}
        alt={product.name}
      />
    </div>
  ) : (
    <CloudImage
      src={image?.src || '/images/no-image.png'}
      alt={product.name}
      className={className}
      width={350}
      height={350}
      style={{
        mixBlendMode: 'multiply',
      }}
      visibleByDefault={visibleByDefault}
    />
  );
};
