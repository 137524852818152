import {FC} from 'react';

interface GrayPinterestProps {
  width?: number;
  height?: number;
}

export const GrayPinterest: FC<GrayPinterestProps> = ({
  width = 16,
  height = 16,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="8" fill="black" />
      <path
        d="M8.17729 2.9668C10.9506 2.9668 13.108 4.94413 13.108 7.5868C13.108 10.3441 11.37 12.5628 8.95662 12.5628C8.14662 12.5628 7.38329 12.1428 7.12329 11.6441L6.62462 13.5461C6.48931 14.0673 6.17996 14.6895 5.89649 15.192L5.76822 15.4146L5.648 15.6154C5.45523 15.5493 5.2658 15.4761 5.08035 15.3954L5.06045 15.1974C5.00364 14.578 4.97279 13.7597 5.11396 13.1548L6.05129 9.17813L6.01885 9.10456L5.98493 9.01609L5.95877 8.93956L5.93129 8.8498L5.90382 8.74749C5.89931 8.72943 5.89486 8.71088 5.89049 8.69184L5.86547 8.57201C5.83401 8.40488 5.81129 8.2089 5.81129 7.99146C5.81129 6.87813 6.45662 6.04813 7.25929 6.04813C7.94196 6.04813 8.27129 6.56013 8.27129 7.1748C8.27129 7.46908 8.19109 7.82582 8.08169 8.2122L8.00499 8.47382L7.79744 9.15214C7.72857 9.3814 7.66294 9.61194 7.60929 9.83813C7.42062 10.6348 8.00796 11.2848 8.79329 11.2848C10.2146 11.2848 11.308 9.7848 11.308 7.6208C11.308 5.70613 9.93129 4.36613 7.96596 4.36613C5.68929 4.36613 4.35263 6.07413 4.35263 7.83813C4.35263 8.52613 4.61796 9.26346 4.94862 9.6648C5.01396 9.7428 5.02329 9.8128 5.00396 9.89346L4.78196 10.8001C4.74662 10.9468 4.66596 10.9775 4.51529 10.9068C3.51529 10.4421 2.89062 8.98013 2.89062 7.8068C2.89062 5.28413 4.72396 2.9668 8.17729 2.9668Z"
        fill="white"
      />
    </svg>
  );
};
