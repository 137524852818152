import {FC, useState} from 'react';
import {
  getProductDashboardPath,
  useExpertLink,
} from '~/modules/Common/hooks/useExpertLink';

import {BottomDrawer} from '~/modules/Common/components/BottomDrawer';
import {Button} from '~/modules/Common/components/Button';
import {ChevronRightIcon} from '@heroicons/react/outline';
import {CollectionsQuickModal} from '~/modules/Collections/components/CollectionsQuickModal';
import {CommissionableLinkBox} from '~/modules/Expert/components/CommissionableLinkBox';
import {Heading} from '~/modules/Common/components/Heading';
import {Link} from '~/modules/Common/components/Link';
import {MergedProduct} from '../context/types';
import {Modal} from 'flowbite-react';
import {ProductImage} from './ProductImage';
import {ProductVisibilityToggle} from '~/modules/Expert/components/ProductVisibilityToggle';
import {Text} from '~/modules/Common/components/Text';
import {TimedToast} from '~/modules/Common/components/TimedToast';
import {createPortal} from 'react-dom';
import {useCardImage} from '../hooks/useCardImage';
import {useExpert} from '~/modules/Common/hooks/useExpert';

interface GenerateLinkShortCutsProps {
  product?: MergedProduct;
  productIds?: string[];
  onClose?: () => void;
  source?: 'default' | 'recommendation';
  isPreview?: boolean;
}

const rowClassName =
  'center-row justify-between p-2 border-b-[1px] border-codGray100 border-solid';
export const GenerateLinkShortCuts: FC<GenerateLinkShortCutsProps> = ({
  product,
  productIds,
  onClose,
  source,
  isPreview = false,
}) => {
  const image = useCardImage(product);
  const isMultiple = (productIds || []).length > 0;
  const title = isMultiple
    ? `${productIds?.length} links generated & products added to your store`
    : isPreview
    ? `${product?.brandName} - ${product?.name}`
    : 'Link generated & product added to your store.';

  const Quick = (
    <QuickActions product={product} productIds={productIds} source={source} />
  );

  const Subheader = isMultiple && (
    <Text internal size="copy">
      {`View the commissionable links in `}
      <Link to={'/my/content/products'}>{`My Content > Products`}</Link>
    </Text>
  );

  return createPortal(
    <>
      <BottomDrawer className="md:hidden" onClose={onClose}>
        <div className="">
          <div className="px-2 flex flex-col gap-2 mb-6">
            <Heading size="headlineSm" internal className="pr-8" as="span">
              {title}
            </Heading>
            {Subheader}
            {!isMultiple && <PromoteSection product={product} />}
          </div>
          {Quick}
          <Button color="light" className="md:hidden mt-4" fullSized>
            Close
          </Button>
        </div>
      </BottomDrawer>
      <Modal
        className="hidden md:flex h-screen"
        onClose={onClose}
        show
        dismissible
        size="xl"
        theme={{
          root: {
            sizes: {
              xl: '!w-[750px]',
            },
          },
        }}
      >
        <Modal.Header>
          <Heading
            size="headlineSm"
            as="span"
            internal
            className="whitespace-nowrap"
          >
            Link generated & product added to your store
          </Heading>
        </Modal.Header>
        <Modal.Body className="flex flex-row justify-center gap-8 !py-6">
          {!isMultiple && (
            <div className="flex-none basis-[275px]">
              {image && (
                <div className="aspect-square bg-codGray50 rounded-sm overflow-hidden relative">
                  <ProductImage
                    product={product}
                    className={`absolute top-0 w-full left-0 group-hover:opacity-50`}
                  />
                </div>
              )}
            </div>
          )}
          <div className="flex flex-auto flex-col gap-8 px-4">
            {Subheader}
            {!isMultiple && <PromoteSection product={product} />}
            {Quick}
          </div>
        </Modal.Body>
      </Modal>
    </>,
    document.body,
  );
};

const PromoteSection: FC<GenerateLinkShortCutsProps> = ({product}) => (
  <div>
    <Heading size="copyBold" className="!text-black my-2" internal>
      Promote this product
    </Heading>
    <CommissionableLinkBox product={product} />
  </div>
);

export const QuickActions: FC<GenerateLinkShortCutsProps> = ({
  product,
  productIds,
  source,
}) => {
  const {expertName} = useExpert();
  const editLink = useExpertLink(
    productIds?.length ? '/' : getProductDashboardPath(product),
  );
  const [isCollectionsModalOpen, setIsCollectionsModalOpen] = useState(false);
  const [isCollectionsAddToastOpen, setIsCollectionsAddToastOpen] =
    useState(false);
  const isMultiple = (productIds || []).length > 0;

  return (
    <>
      <div>
        {/* <Heading size="fineBold" className="!font-semibold uppercase px-2">
          Quick Actions
        </Heading> */}

        <div className="flex flex-col p-0 m-0 gap-0">
          {!isMultiple && (
            <div className={rowClassName}>
              <Text size="sm" as="p" internal>
                Display product{isMultiple ? 's' : ''} in store
              </Text>
              <ProductVisibilityToggle
                product={product}
                expertName={expertName}
                showLabel={false}
              />
            </div>
          )}
          <div
            className={rowClassName}
            role="button"
            onClick={() => setIsCollectionsModalOpen(true)}
          >
            <Text size="sm" as="p" internal>
              Add Product{isMultiple ? 's' : ''} to Collection
            </Text>
            <ChevronRightIcon height={20} width={20} />
          </div>
          {!isMultiple && (
            <Link to={editLink} noPrefix className={rowClassName}>
              <Text size="sm" as="span" internal>
                Personalize Product Page
              </Text>
              <ChevronRightIcon height={20} width={20} />
            </Link>
          )}
        </div>
      </div>
      {isCollectionsModalOpen && (
        <CollectionsQuickModal
          product={product}
          productIds={productIds}
          source={source}
          onClose={(isSuccess: boolean) => {
            setIsCollectionsModalOpen(false);
            if (isSuccess) {
              setIsCollectionsAddToastOpen(true);
            }
          }}
        />
      )}
      <TimedToast
        message={`Collections updated successfully.`}
        isSuccess={isCollectionsAddToastOpen}
        parentSetter={() => setIsCollectionsAddToastOpen(false)}
      />
    </>
  );
};
