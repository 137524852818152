import {
  FC,
  HTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {ClientOnly} from 'remix-utils';
import {CloudImage} from '~/modules/Common/components/CloudImage';
import {AmazonImage} from '~/modules/Common/components/AmazonImage';
import {GalleryThumbnails} from './GalleryThumbnails';
import {Media} from '~/gql/generated';
import ReactPlayer from 'react-player';
import {Media as ShopifyMedia} from '@shopify/hydrogen/storefront-api-types';
import {getMediaProps} from '~/modules/Common/util';
import useBreakpoints from '~/modules/Common/hooks/useBreakpoint';
import {useIntersection} from 'react-use';

export function ProductGallery({
  media,
  firstImage,
  className,
  isAmazonImage,
}: {
  firstImage?: Media | ShopifyMedia;
  media: Media[];
  className?: string;
  isAmazonImage: boolean
}) {
  const containerRef = useRef<HTMLDivElement>(null);
  const merged = useMemo(() => {
    if (firstImage) {
      return [
        firstImage,
        ...(media || []).filter((m) => {
          return m.src !== firstImage.src && firstImage.src !== m.image?.url;
        }),
      ];
    }
    return media;
  }, [media, firstImage]);
  const {isMd} = useBreakpoints();
  const onThumbClick = useCallback(
    (index: number) => {
      if (containerRef.current !== null) {
        containerRef.current.scrollLeft =
          // @ts-expect-error stuff
          containerRef.current?.childNodes[index].offsetLeft;
      }
    },
    [containerRef],
  );

  useEffect(() => {
    if (containerRef.current !== null) {
      containerRef.current.scrollLeft = 0;
    }
  }, [containerRef, merged]);

  if (!merged.length) {
    return null;
  }

  return (
    <>
      <div
        ref={containerRef}
        className={`swimlane px-3 md:grid-flow-row hiddenScroll -mt-2 md:mt-0 py-4 md:p-0 md:overflow-x-auto md:grid-cols-2  bg-codGray50 md:bg-transparent ${className}`}
        style={{
          scrollBehavior: 'smooth',
        }}
      >
        {merged.map((med, i) => {
          const isFullWidth = i % 3 === 0;
          const isNaturalSizeImage = isFullWidth;
          const isOnly = merged.length === 1;
          const {isVideo, src, alt, isExpert} = getMediaProps(med);

          const style = [
            isFullWidth ? 'md:col-span-2' : 'md:col-span-1',
            isNaturalSizeImage ? '' : 'md:aspect-square',
            'aspect-square snap-center bg-white md:w-full rounded-md overflow-hidden',
            isOnly ? 'w-mobileGalleryFull' : 'w-mobileGallery',
          ].join(' ');
          const width = isMd ? 896 : 400;

          const logic =
            isNaturalSizeImage && isMd
              ? `w_${width},h_${width},c_pad,b_rgb:f6f6f6`
              : `if_iar_eq_1.0/w_${width},q_80,c_fill${
                  isExpert ? ',g_face' : ''
                }/if_end/if_iar_ne_1/w_${width},q_80,c_pad,h_${width},b_rgb:f6f6f6/if_end`;

          return (
            <IntersectionDiv
              className={`${style} ${isVideo ? '' : ' card-image '}`}
              key={src}
              alwaysVisible={!isVideo}
            >
              {() => (
                <>
                  {!isVideo && !isAmazonImage && (
                    <CloudImage
                      className="fadeIn"
                      visibleByDefault={i < 3}
                      src={`${src}${isExpert ? `#${isMd}` : ''}}`}
                      alt={alt}
                      logicString={logic}
                    />
                  )}
                  {!isVideo && isAmazonImage && (
                    <AmazonImage
                      className="fadeIn"
                      width={890}
                      height={890}
                      src={src}
                      alt={alt}
                    />
                  )}
                  {isVideo && (
                    <ClientOnly fallback={<div />}>
                      {() => (
                        <ReactPlayer
                          width="100%"
                          height="100%"
                          style={{background: '#000'}}
                          url={src}
                          controls={true}
                        />
                      )}
                    </ClientOnly>
                  )}
                </>
              )}
            </IntersectionDiv>
          );
        })}
      </div>
      <div className="md:hidden">
        {merged.length > 1 && (
          <GalleryThumbnails mergedMedia={merged} onThumbClick={onThumbClick} />
        )}
      </div>
    </>
  );
}

interface IntersectionDivProps extends HTMLAttributes<HTMLDivElement> {
  children: () => JSX.Element;
  alwaysVisible?: boolean;
}

const IntersectionDiv: FC<IntersectionDivProps> = ({
  children,
  alwaysVisible,
  ...props
}) => {
  const divRef = useRef<HTMLDivElement>(null);
  const intersection = useIntersection(divRef, {
    root: null,
    rootMargin: '100px',
    threshold: 0.3,
  });
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    if (!isVisible && intersection && intersection?.isIntersecting) {
      setIsVisible(true);
    }
  }, [intersection, intersection?.isIntersecting, isVisible]);

  return (
    <div ref={divRef} {...props}>
      {isVisible || alwaysVisible ? children() : null}
    </div>
  );
};
