import {FC, PropsWithChildren} from 'react';
import {Tooltip} from 'flowbite-react';

import {BrandLogo} from '~/modules/Brand/components/BrandLogo';
import {BrandSearchResponse} from '~/gql/generated';
import {Heading} from '~/modules/Common/components/Heading';
import {Text} from '~/modules/Common/components/Text';
import {formatCommissionValue} from '~/modules/Common/util';

interface ExpertBrandResponseInfoProps {
  brand: BrandSearchResponse;
}

export const ExpertBrandResponseInfo: FC<
  PropsWithChildren<ExpertBrandResponseInfoProps>
> = ({brand, children}) => (
  <div className="flex flex-row gap-4 flex-nowrap items-center">
    <div>
      <BrandLogo
        logoUrl={brand.logoUrl}
        name={brand.name || brand.companyInfo?.name}
      />
    </div>
    <div>
      <Tooltip content={brand.name || brand.companyInfo?.name} placement='bottom'>
        <Heading
          size="headlineSm"
          className="line-clamp-2"
        >
          {brand.name || brand.companyInfo?.name}
        </Heading>
      </Tooltip>
      {brand.commission?.amountType && (
        <div className="flex-col flex">
            <Text size="sm" internal>
              <strong>Commission: </strong>
              ~{Math.round(Number(brand.avgCommission || 0))}%
            </Text>
        </div>
      )}
      {children}
    </div>
  </div>
);
