import {Await, useMatches} from '@remix-run/react';
import {Suspense, useMemo} from 'react';

import {Link} from '~/modules';
import {ShoppingCartIcon} from '@heroicons/react/outline';
import {useIsHydrated} from '~/modules/Common/hooks/useIsHydrated';

export function CartCount({openCart}: {openCart: () => void}) {
  const [root] = useMatches();
  return (
    <Suspense fallback={<Badge openCart={openCart} count={0} />}>
      <Await resolve={root?.data?.cartData}>
        {(cartData) => {
          return (
            <Badge
              openCart={openCart}
              count={cartData?.cart?.totalQuantity || 0}
            />
          );
        }}
      </Await>
    </Suspense>
  );
}

function Badge({openCart, count}: {count: number; openCart: () => void}) {
  const isHydrated = useIsHydrated();

  const BadgeCounter = useMemo(
    () => (
      <>
        <div className="text-expertPrimary">
          <ShoppingCartIcon width={'24px'} height={'24px'} />
        </div>
        <div
          className={
            'bg-codGray200 absolute bottom-0 right-0 text-[0.625rem] font-medium subpixel-antialiased h-3 min-w-[0.75rem] flex items-center justify-center leading-none text-center rounded-full w-auto px-[0.125rem] pb-px'
          }
        >
          <span>{count || 0}</span>
        </div>
      </>
    ),
    [count],
  );

  return isHydrated ? (
    <button
      onClick={openCart}
      className="relative flex items-center justify-center w-6 h-6 focus:ring-primary/5"
    >
      {BadgeCounter}
    </button>
  ) : (
    <Link
      to="/cart"
      className="relative flex items-center justify-center w-6 h-6 focus:ring-primary/5"
    >
      {BadgeCounter}
    </Link>
  );
}
