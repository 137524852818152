import {MergedProduct} from '../context/types';
import {useProduct} from './useProduct';

export const useProductPrice = (product?: MergedProduct) => {
  const contextProduct = useProduct(product?.id);
  product = product || contextProduct;
  const variantPrice =  parseFloat(product?.price || '');
  let discountValue;
  if (product?.amazonBuyBox) {
    return {
      isOnSale: Number(product.amazonBuyBox.strikeThroughPrice) > Number(product.price),
      compareAtPrice: {
        amount: Number(product.amazonBuyBox.strikeThroughPrice) || Number(product.price),
        currencyCode: 'USD',
      },
      price: {
        amount: variantPrice,
        currencyCode: 'USD',
      },
      discount: {
        amountValue: product.amazonBuyBox.discountPer,
        displayStrikethrough: Number(product.amazonBuyBox.strikeThroughPrice) > 0,
      },
    }
  }
  discountValue = parseFloat(product?.discount?.amountValue || '') || 0;
  let discount = 0;
  if (discountValue > 0 && !product?.discount?.minimumOrderValue) {
    discount =
      product?.discount?.amountType === 'PERCENT'
        ? (discountValue / 100) * variantPrice
        : discountValue;
  }
  const isOnSale = variantPrice > 0 && discount > 0;

  return {
    isOnSale,
    hasCartDiscount: !!product?.discount?.minimumOrderValue,
    compareAtPrice: {
      amount: variantPrice,
      currencyCode: 'USD',
    },
    price: {
      amount: `${variantPrice - discount}`,
      currencyCode: 'USD',
    },
    discount: product?.discount,
  };
};
