import {AnalyticsEvent} from '~/modules/Common/hooks/analyticsEvent';
import {MergedProduct} from '~/modules/Product/context/types';
import {Product} from '@shopify/hydrogen/storefront-api-types';
import {useExpertLink} from '~/modules/Common/hooks/useExpertLink';
import {useFetcher} from '@remix-run/react';
import {useRudderStack} from '~/modules/Common/hooks/useRudderstack';

export const useToggleProductVisibilty = (
  expertName: string,
  product: Product | MergedProduct,
) => {
  const {trackEvent} = useRudderStack();
  const action = useExpertLink(`/my/content/products`);
  const fetcher = useFetcher();

  const toggleProductVisibility = async (isVisibleInStore: boolean) => {
    await fetcher.submit(
      {
        expertName,
        productId: product.id,
        action: 'visibility',
        isVisibleInStore: String(isVisibleInStore),
      },
      {method: 'post', action},
    );
    trackEvent(AnalyticsEvent.creatorProductVisibilityChanged, {
      productId: product.id,
      isVisibleInStore,
      expertName,
    });
    return fetcher.data;
  };
  return toggleProductVisibility;
};
