import {useExpertLink} from '~/modules/Common/hooks/useExpertLink';

export const useUpdateCollections = () => {
  const action = useExpertLink('/my/api/collections');

  const updateCollections = async (
    productIds: string,
    add: string[],
    remove: string[],
  ) => {
    const response = await fetch(action, {
      method: 'post',
      body: JSON.stringify({
        productIds,
        add,
        remove,
        action: 'update',
      }),
    });
    return await response.json();
  };
  return updateCollections;
};
