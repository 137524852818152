import {FC, useEffect, useMemo, useRef, useState} from 'react';
import {
  useExpertLink,
  useExpertProductLink,
} from '~/modules/Common/hooks/useExpertLink';

import {CloudImage} from '~/modules/Common/components/CloudImage';
import {Link} from '~/modules/Common/components/Link';
import {SearchAutocompleteResult} from '../types';
import {Product as ShopifyProduct} from '@shopify/hydrogen/storefront-api-types';
import {Text} from '~/modules/Common/components/Text';

type ItemClick = (opts: {
  type: 'brand' | 'product';
  brandSlug?: string;
  brandName?: string;
  productId?: string;
  externalId?: string;
}) => void;
interface SearchAutocompleteResultsListProps {
  result: SearchAutocompleteResult | null;
  shopifyProducts?: ShopifyProduct[];
  term: string;
  onItemClick: ItemClick;
  productLimit?: number;
}

export const SearchAutocompleteResultsList: FC<
  SearchAutocompleteResultsListProps
> = ({result, term, shopifyProducts, onItemClick, productLimit = Infinity}) => {
  const [limitBrands, setLimitBrands] = useState(3);
  const prevTerm = useRef(term);
  useEffect(() => {
    if (prevTerm.current !== term) {
      setLimitBrands(3);
      prevTerm.current = term;
    }
  }, [term]);

  return result ? (
    <>
      {result.brands?.length > 0 || result.products?.length > 0 ? (
        <div className="bg-white w-full max-w-[620px] py-2 overflow-y-auto max-h-[calc(100vh-58px)] md:max-h-[400px]">
          {result.brands?.length > 0 && (
            <>
              <Text
                size="fine"
                className="uppercase px-2 text-codGray800 font-semibold"
              >
                Brands
              </Text>
              <div className="py-2 flex flex-col gap-2">
                {result.brands.slice(0, limitBrands).map((brand) => (
                  <BrandResultRow
                    key={brand.brandId}
                    brandName={brand.brandName}
                    brandLogoUrl={brand.brandLogoUrl}
                    brandSlug={brand.brandSlug}
                    onItemClick={onItemClick}
                  />
                ))}
                {result.brands.length > limitBrands && (
                  <button
                    className="underline px-2 !text-left"
                    onClick={() => setLimitBrands(result.brands.length)}
                  >
                    <Text size="sm" className="text-codGray800">
                      Show {result.brands.length - limitBrands} more brands
                    </Text>
                  </button>
                )}
              </div>
            </>
          )}
          {result.products?.length > 0 && (
            <>
              <Text
                size="fine"
                className="uppercase px-2 text-codGray800 font-semibold"
              >
                Products
              </Text>
              <div className="py-2 flex flex-col gap-2">
                {result.products.slice(0, productLimit).map((product) => (
                  <ProductResultRow
                    key={product.product.id}
                    product={product.product}
                    shopifyProducts={shopifyProducts}
                    onItemClick={onItemClick}
                  />
                ))}
              </div>
            </>
          )}
        </div>
      ) : term.length > 0 ? (
        <div className="bg-white p-2">
          <Text size="copy" as="p" className="block">
            No results for &ldquo;{term}&rdquo;
          </Text>
          <Text size="sm" as="p">
            Check the spelling or use a different word or phrase
          </Text>
        </div>
      ) : null}
    </>
  ) : null;
};

const BrandResultRow: FC<{
  brandName: string;
  brandLogoUrl: string;
  brandSlug: string;
  onItemClick: ItemClick;
}> = ({brandName, brandLogoUrl, brandSlug, onItemClick}) => {
  const link = useExpertLink(`/brand/${brandSlug}`);
  return (
    <Link
      to={link}
      className="center-row gap-2 hover:bg-codGray100 px-2 py-1"
      onClick={() => onItemClick({type: 'brand', brandSlug, brandName})}
    >
      {(brandLogoUrl || '').replace('https://wayward.com', '').length > 0 && (
        <div className="flex-none basis-[40px] aspect-square rounded-lg overflow-hidden relative">
          <CloudImage
            visibleByDefault
            alt={brandName}
            src={brandLogoUrl}
            height={92}
            width={92}
            quality={'100'}
            crop="scale"
            style={{borderRadius: '9999px'}}
          />
        </div>
      )}
      {!brandLogoUrl && (
        <div className="bg-codGray200 h-[40px] w-[40px] rounded-full shrink-0 flex justify-center items-center">
          <Text>{brandName.slice(0, 2).toUpperCase()}</Text>
        </div>
      )}
      <Text size="sm">{brandName}</Text>
    </Link>
  );
};

const ProductResultRow: FC<{
  product: SearchAutocompleteResult['products'][0]['product'];
  shopifyProducts?: ShopifyProduct[];
  onItemClick: ItemClick;
}> = ({product, shopifyProducts, onItemClick}) => {
  const shopifyProduct = useMemo(
    () => shopifyProducts?.find((p) => p.id === product.externalId),
    [shopifyProducts, product],
  );
  const link = useExpertProductLink({...product, brand: shopifyProduct});

  return (
    <Link
      to={link}
      className="center-row gap-2 hover:bg-codGray100 px-2 py-1"
      onClick={() =>
        onItemClick({
          type: 'product',
          productId: product.id,
          externalId: product.externalId,
        })
      }
    >
      <div className="flex-none basis-[40px] aspect-square rounded-lg overflow-hidden relative">
        <CloudImage
          visibleByDefault
          alt={shopifyProduct?.name || product.name}
          src={
            shopifyProduct?.media?.nodes?.[0]?.image?.url || product.image.src
          }
          height={350}
          width={350}
          quality={'100'}
          crop="scale"
          style={{borderRadius: '9999px'}}
        />
      </div>
      <div className="flex flex-col">
        <Text size="fine" className="text-codGray800">
          {product.brandName}
        </Text>
        <Text size="sm">{shopifyProduct?.title || product.name}</Text>
      </div>
    </Link>
  );
};
