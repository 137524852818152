import {MenuIcon, SearchIcon} from '@heroicons/react/outline';

import {CartCount} from '~/modules/Cart/components/CartCount';
import {HeaderLogo} from './HeaderLogo';
import {LogoTypes} from '~/modules/Appearance/types';
import {ReactNode} from 'react';
import {useExpertTheme} from '~/modules/Expert/hooks/useExpertTheme';

export function MobileHeader({
  title,
  logo,
  logoType,
  isHome,
  openCart,
  openMenu,
  openSearch,
  hasMenu,
}: {
  title: string | ReactNode;
  logo: string;
  logoType?: LogoTypes;
  isHome: boolean;
  isSearchOpen: boolean;
  openCart: () => void;
  openMenu?: () => void;
  openSearch?: () => void;
  hasMenu?: boolean;
}) {
  const {isDefault} = useExpertTheme();

  return (
    <header
      role="banner"
      className={`bg-codGray50 text-primary flex lg:hidden items-center h-nav sticky backdrop-blur-lg z-40 top-0 justify-between w-full leading-none gap-4 px-4 md:px-8`}
    >
      {!isDefault && (
        <div className="flex items-center justify-start gap-4 flex-none">
          {hasMenu && (
            <button
              onClick={openMenu}
              className="relative flex items-center justify-center text-expertPrimary"
            >
              <MenuIcon width={20} height={20} />
            </button>
          )}
          <button type="button" onClick={openSearch}>
            <SearchIcon width={20} height={20} />
          </button>
        </div>
      )}
      <HeaderLogo
        isHome={isHome}
        logo={logo}
        logoType={logoType}
        title={title}
      />

      <div className="flex items-center justify-end gap-4 flex-none">
        <CartCount openCart={openCart} />
      </div>
    </header>
  );
}
