import {MergedProduct} from '../context/types';
import {loader} from '~/routes/($expertName)/products/$productHandle.($productId)';
import {useLoaderData} from '@remix-run/react';

export const useSelectedVariant = (product?: MergedProduct) => {
  const {shopifyProduct} = useLoaderData<typeof loader>();
  if (product) {
    return product?.selectedVariant ?? product?.variants?.nodes?.[0];
  }
  return (
    shopifyProduct?.selectedVariant ?? shopifyProduct?.variants?.nodes?.[0]
  );
};
