import {MergedProduct} from '../context/types';
import {ProductsContext} from '../context/ProductsContext';
import {useContext} from 'react';

export const useProduct = (internalId?: string): MergedProduct | undefined => {
  const {products} = useContext(ProductsContext);
  return internalId
    ? products.find(({id}) => id === internalId)
    : products?.[0];
};
