import { Plus } from 'lucide-react';
import {
  ExternalCallout,
  GenerateLinkControls,
} from '~/modules/Product/components/GenerateLinkControls';
import {Button} from '~/modules/Common/components/Button';

import {FC, useState} from 'react';
import {Link} from '~/modules/Common/components/Link';
import {MergedProduct} from '~/modules/Product/context/types';
import {Text} from '~/modules/Common/components/Text';
import CreateAmazonAffiliateLinkModal from './CreateAmazonAffiliateLinkModal';
import ViewProductLinksModal from './ViewProductLinksModal';

interface CommissionableLinkBoxProps {
  product: MergedProduct;
  showText?: boolean;
  showBorder?: boolean;
}

export const CommissionableLinkBox: FC<CommissionableLinkBoxProps> = ({
  product,
  showText = true,
  showBorder = true
}) => {
  const [isCreateLinkModalOpen, setCreateLinkModalOpen] = useState<boolean>(false);
  const [isViewLinkModalOpen, setViewLinkModalOpen] = useState<boolean>(false);

  const handleCreateLinkModalVisibility = () => {
    setCreateLinkModalOpen((prev) => !prev);
  }

  const handleViewLinksModalVisibility = () => {
    setViewLinkModalOpen((prev) => !prev);
  }

  const getLatestLink = () => {
    if (product.viewAllLinks?.length) {
      const linkType = product.brandType?.toLowerCase() === 'amazon' ? 'amazon' : 'pdp';
      const filteredLinks = product.viewAllLinks.filter((item) => item?.linkType?.toLowerCase() === linkType);
      const initialLinkCreatedDate = new Date(filteredLinks[0]?.createdAt?.split('+')[0].trim() || '').getTime();
      const index = filteredLinks?.findIndex((item) => initialLinkCreatedDate <= new Date(item?.createdAt?.split('+')[0].trim() || '').getTime());
      if (index > -1) {
        const replacementName = linkType === 'amazon' ? 'Default Amazon Link' : 'Wayward Standard PDP';
        return { ...filteredLinks[index], sourceName: filteredLinks[index]?.sourceName || replacementName}
      } else {
        return filteredLinks[index] || product.viewAllLinks[0];
      }
    }
  }

  return (
    <div
      className={`grid ${
        product.viewAllLinks?.length
          ? 'grid-cols-[50%_50%]'
          : 'grid-cols-1 justify-center border-none'
      } gap-2 md:flex md:flex-col md:gap-2 border-[1px] border-codGray100 rounded-md overflow-hidden
      ${showBorder ? 'border-dashed p-2' : 'border-none p-0'}`}
    >
      {product.viewAllLinks?.length > 0 && (
        <>
          <div className="min-w-[1px] relative">
            {showText && (
              <Text size="sm" as="p" className="block font-bold">
                {getLatestLink()?.sourceName}
              </Text>
            )}
            <div className='flex items-center'>
              <Link
                to={getLatestLink()?.shortlinkUrl}
                noPrefix
                className="md:relative whitespace-nowrap overflow-hidden select-none	"
                target="_blank"
              >
                <Text
                  size="sm"
                  className="underline whitespace-nowrap overflow-hidden"
                  as="p"
                  internal
                >
                  <span>{getLatestLink()?.shortlinkUrl}</span>
                </Text>
              </Link>
              <ExternalCallout
                className={
                  'center-row md:items-end pl-1'
                }
                product={product}
              />
            </div>
          </div>
        </>
      )}
      {/* Assumption that this is only used within components where a creator already has products */}
      <GenerateLinkControls
        isExternalVisible={false}
        product={product}
        hasProducts={true}
        shouldAddProduct={false}
      />
      <div className='flex gap-2 md:justify-between col-span-2'>
        <Button  size="sm" color='light' onClick={handleViewLinksModalVisibility} className='flex-auto'>
          View all ({product.viewAllLinks?.length}) links
        </Button>
        {
          product.brandType?.toUpperCase() === 'AMAZON' && (
            <Button
              size="sm"
              color='light'
              className='items-center gap-2 flex-auto'
              onClick={handleCreateLinkModalVisibility}
            >
              <Plus size={12} />
              Create new link
            </Button>
          )
        }
      </div>
      {isCreateLinkModalOpen && (
        <CreateAmazonAffiliateLinkModal
          closeModal={handleCreateLinkModalVisibility}
          productId={product.id}
        />
      )}
      {isViewLinkModalOpen && (
        <ViewProductLinksModal
          closeModal={handleViewLinksModalVisibility}
          productId={product.id}
          openCreateAffiliateModal={handleCreateLinkModalVisibility}
          brandType={product.brandType || ''}
        />
      )}
    </div>
  );
};
