import {ExpertIdsContext} from '../context/ExpertIdsContext';
import {useContext} from 'react';

export const useExpertProductIds = () => {
  const {expertIds, setIds} = useContext(ExpertIdsContext);
  return {
    expertIds,
    setIds,
  };
};
