import {FC, useState} from 'react';
import {ExternalLink, X, ChevronDown, ChevronUp} from 'lucide-react';
import {useLoaderData} from '@remix-run/react';

import {BottomDrawer} from '~/modules/Common/components/BottomDrawer';
import {Button} from '~/modules/Common/components/Button';
import {GenerateLinkControls} from './GenerateLinkControls';
import {QuickActions} from './GenerateLinkShortCuts';
import {MergedProduct} from '../context/types';
import {Modal} from 'flowbite-react';
import {PriceDisplay} from './ProductPrice';
import {ProductImage} from './ProductImage';
import {Text} from '~/modules/Common/components/Text';
import {getSelectedVariant} from '../utils/selecterVariant.util';
import {isProductIngested} from '../utils/productUtils';
import {useProductPrice} from '../hooks/useProductPrice';
import {useSelectedVariant} from '../hooks/useSelectedVariant';
import {StarRating} from '~/modules/Reviews/components/StarRating';
import RequestSampleModal from '~/modules/Product/components/RequestSampleModal';
import {BrandType} from '~/modules/Product/components/ProductBrandBadge';
import {CommissionableLinkBox} from '~/modules/Expert/components/CommissionableLinkBox';
import {useExpertLink} from '~/modules/Common/hooks/useExpertLink';
import {WAYWARD_ASSOCIATE_TAG} from '~/lib/const';
import {PartnershipStatus} from '~/gql/generated';
import {BrandApplyModal} from '~/modules/Expert/components/BrandApplyModal';

interface ProductPreviewModalProps {
  product: MergedProduct;
  onClose?: () => void;
  source?: 'default' | 'recommendation';
  commission: string;
  hideCreate?: boolean;
}

interface ProductDetailsProps {
  product: MergedProduct;
  source: 'default' | 'recommendation';
  commission: string;
  onClose: (() => void) | undefined;
}

export const ProductPreviewModal: FC<ProductPreviewModalProps> = ({
  product,
  onClose,
  source = 'default',
  commission,
  hideCreate,
}) => {
  const isIngested = isProductIngested(product);
  const initialSelected = useSelectedVariant(product);
  const [selectedVariant, setSelectedVariant] = useState(initialSelected);

  const onOptionClick = (name: string, value: string) => {
    setSelectedVariant(
      getSelectedVariant(product, product.brand, [{name, value}]),
    );
  };

  return (
    <>
      <BottomDrawer className="md:hidden" onClose={onClose}>
        <div className="pt-10 max-h-[80vh] overflow-y-auto">
          <ProductDetails
            product={product}
            source={source}
            commission={commission}
            onClose={onClose}
          />
        </div>
      </BottomDrawer>
      <Modal
        className="hidden md:flex h-screen"
        onClose={onClose}
        show
        dismissible
        size="xl"
        theme={{
          root: {
            sizes: {
              xl: '!w-[800px]',
            },
          },
        }}
      >
        <Modal.Body className="flex flex-row gap-8 !py-8 relative">
          <X
            className="absolute top-2 right-2 cursor-pointer text-codgray600"
            onClick={onClose}
          />
          <ProductDetails
            product={product}
            source={source}
            commission={commission}
            onClose={onClose}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

const ProductDetails: FC<ProductDetailsProps> = ({
  product,
  source,
  commission,
  onClose,
}: ProductDetailsProps) => {
  const category = product.categories?.length
    ? product.categories[0].name
    : 'None';
  const data = useLoaderData();
  const {isHighScoreExpert} = data;
  const [isRequestSampleModalVisible, setIsRequestSampleModalVisible] =
    useState(false);
  const {isOnSale, compareAtPrice, price, discount} = useProductPrice(product);
  const [showStorefrontAction, setShowStoreFrontActions] =
    useState<boolean>(false);
  const [isApplyModalOpen, setIsApplyModalOpen] = useState(false);
  const isDiscount = isOnSale && discount && Number(discount?.amountValue) > 0;

  const isAmazonBrand = product.brandType === BrandType.AMAZON;
  const isAffiliateBrand = product.brandType === BrandType.AFFILIATE;
  const amazonSettingLink = useExpertLink('/my/amazonsettings');

  return (
    <div className="md:flex gap-6 px-3 w-full mb-[210px] md:mb-0">
      <div className="flex flex-col gap-5 md:w-1/2">
        <div className="relative min-h-[150px] min-w-[150px] block md:hidden">
          <ProductImage product={product} />
        </div>
        <Text className="w-full  text-codGray" as="h4" size="copy">
          {product.name}
        </Text>
        {isAmazonBrand && (
          <a
            className="text-purple-800 flex gap-2 items-center md:mb-0"
            href={`https://amazon.com/d/${product.externalId}?tag=${WAYWARD_ASSOCIATE_TAG}`}
            target="_blank"
            rel="noreferrer"
          >
            View on Amazon <ExternalLink size={20} />
          </a>
        )}
        <div className="relative min-h-[150px] min-w-[150px] hidden md:block">
          <ProductImage product={product} />
        </div>
        {isAmazonBrand &&
          isHighScoreExpert &&
          Number(product.viewAllLinks?.length) > 0 &&
          !isAffiliateBrand && (
            <div className="flex-1 mb-3">
              <Button
                color="light"
                className="shadow-cell"
                onClick={() => setIsRequestSampleModalVisible(true)}
              >
                Request Sample
              </Button>
            </div>
          )}
      </div>
      <div className="bg-codGray100 h-full w-px" />
      <div className="md:w-1/2">
        {isAffiliateBrand && !data.expert.amzAssociateTag && (
          <Text className="text-black text-left my-6" size="subtext" as="p">
            <a
              href={amazonSettingLink}
              className="text-purple-800 underline underline-offset-4"
            >
              Add your Amazon Associate ID
            </a>
            &nbsp; to create a link.
          </Text>
        )}
        <div
          className="relative grid gap-4"
          style={{gridTemplateRows: 'min-content'}}
        >
          <Text
            className="w-full  text-codGray mb-2 md:mb-0"
            as="h4"
            size="copyHeadline"
          >
            Product Details
          </Text>
          <div className="flex flex-col gap-2">
            {isAmazonBrand && (
              <div className="flex justify-between flex-col sm:flex-row sm:gap-6">
                <Text className="text-codGray500">ASIN:</Text>
                <Text className="text-black">{product.externalId}</Text>
              </div>
            )}
            <div className="flex justify-between flex-col sm:flex-row sm:gap-6">
              <Text className="text-codGray500">Brand:</Text>
              <Text className="text-black">{product.brandName}</Text>
            </div>
            <div className="flex justify-between flex-col sm:flex-row sm:gap-6">
              <Text className="text-codGray500">Category:</Text>
              <Text className="text-black" widths="full" as="div">
                {category}
              </Text>
            </div>
            {isAmazonBrand && (
              <div className="flex justify-between flex-col sm:flex-row sm:gap-6">
                <Text className="text-codGray500">Best Seller Rank:</Text>
                <Text className="text-black">
                  {product.amazonBuyBox?.bestSellerRank}
                </Text>
              </div>
            )}
            {isAmazonBrand && Number(product.amazonBuyBox?.reviewCount) > 0 && (
              <div className="flex justify-between flex-col sm:flex-row sm:gap-6">
                <Text className="text-codGray500">Rating:</Text>
                <div className="flex gap-1">
                  <StarRating
                    rating={Number(product.amazonBuyBox?.avgRating)}
                  />
                  <Text className="text-black">
                    {parseFloat(
                      product.amazonBuyBox?.reviewCount || '0',
                    ).toLocaleString()}
                  </Text>
                </div>
              </div>
            )}
            <div className="flex justify-between flex-col sm:flex-row sm:gap-6">
              <Text className="text-codGray500">Price:</Text>
              <PriceDisplay
                isOnSale={isOnSale}
                compareAtPrice={compareAtPrice}
                price={price}
                discount={discount}
                size="text-base"
                priceFontClass={'font-normal'}
                showDeal={false}
              />
            </div>
            {((product.couponType && Number(product.couponVal) > 0) ||
              isDiscount) && (
              <div className="flex justify-between flex-col sm:flex-row sm:gap-6">
                <Text className="text-codGray500">Deal:</Text>
                <span>
                  {isDiscount && (
                    <span className="text-red-500">
                      {Math.round(+discount?.amountValue)}% off
                    </span>
                  )}
                  {isDiscount &&
                    product.couponType &&
                    Number(product.couponVal) > 0 && (
                      <span className="text-red-500">&nbsp; + &nbsp;</span>
                    )}
                  {product.couponType && Number(product.couponVal) > 0 && (
                    <span className="text-red-500">
                      {product.couponType?.toLowerCase() === 'percent'
                        ? `${Math.round(+product.couponVal)}% `
                        : `$${product.couponVal}`}{' '}
                    </span>
                  )}
                </span>
              </div>
            )}
            <div className="flex justify-between flex-col sm:flex-row sm:gap-6">
              <Text className="text-codGray500">
                {isAffiliateBrand ? 'Associate Commission:' : 'Commission:'}
              </Text>
              <Text className="text-black">
                {commission ||
                  (product.commission?.amountValue
                    ? `${parseFloat(product.commission.amountValue)}%`
                    : '')}
                {commission && product.commission?.amountValue
                  ? ` (${parseFloat(product.commission.amountValue)}%)`
                  : ''}
              </Text>
            </div>
            <div className="fixed md:relative w-full bg-white md:bg-transparent bottom-0 left-0 p-5 md:p-0 pb-5 md:pb-0 drop-shadow-2xl md:drop-shadow-none">
              {Number(product.viewAllLinks?.length) > 0 ? (
                <div>
                  <hr className="mt-4 mb-4 hidden md:block" />
                  <CommissionableLinkBox product={product} showBorder={false} />
                  <hr className="mt-4 mb-4 hidden md:block" />
                  <div
                    className="flex gap-4 items-end cursor-pointer w-fit"
                    onClick={() => setShowStoreFrontActions((prev) => !prev)}
                    aria-hidden="true"
                  >
                    <Text
                      className="text-black font-bold mt-4 md:mt-0"
                      size="sm"
                    >
                      {showStorefrontAction
                        ? 'Hide storefront actions'
                        : 'Show storefront actions'}
                    </Text>
                    {showStorefrontAction ? (
                      <ChevronUp size={16} />
                    ) : (
                      <ChevronDown size={16} />
                    )}
                  </div>
                </div>
              ) : (
                <div>
                  <div className="flex gap-2 mt-3 items-end">
                    <div className="flex-1">
                      {product.requiresApproval &&
                        product.partnershipStatus ===
                          PartnershipStatus.PendingApproval && (
                          <Button
                            className="shadow-cell w-full whitespace-nowrap cursor-default"
                            color="light"
                          >
                            Applied, Pending Review
                          </Button>
                        )}
                      {product.requiresApproval &&
                        product.partnershipStatus === '' && (
                          <Button
                            className="shadow-cell w-full whitespace-nowrap"
                            onClick={() => setIsApplyModalOpen(true)}
                          >
                            Apply to sell
                          </Button>
                        )}
                      {!product.requiresApproval && (
                        <GenerateLinkControls
                          product={product}
                          isExternalVisible={false}
                          shouldAddProduct={true}
                          source={source}
                          createLinkClassname={'!h-[46px]'}
                        />
                      )}
                    </div>
                    {!isAffiliateBrand && isHighScoreExpert && (
                      <div className="flex-1">
                        <Button
                          color="light"
                          className="shadow-cell w-full whitespace-nowrap"
                          onClick={() => setIsRequestSampleModalVisible(true)}
                        >
                          Request Sample
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {showStorefrontAction && (
                <QuickActions product={product} source={source} />
              )}
            </div>
          </div>
          <RequestSampleModal
            isVisible={isRequestSampleModalVisible}
            product={product}
            onClose={() => setIsRequestSampleModalVisible(false)}
          />
          {isApplyModalOpen && (
            <BrandApplyModal
              brand={{
                name: product.brandName,
                id: product.brandId,
                requiresApproval: product.requiresApproval,
                logoUrl: product.brandLogoUrl,
              }}
              onClose={() => setIsApplyModalOpen(false)}
              onSuccess={() => {
                setIsApplyModalOpen(false);
                if (onClose) onClose();
              }}
              productId={product.id}
            />
          )}
        </div>
      </div>
    </div>
  );
};
