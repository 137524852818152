import {CloudImage} from '~/modules/Common/components/CloudImage';
import {FC} from 'react';
import {Heading} from '~/modules/Common/components/Heading';

interface BrandLogoProps {
  logoUrl: string;
  name: string;
  size?: 16 | 32 | 64 | 92 | 180;
  rounded?: boolean;
}

export const BrandLogo: FC<BrandLogoProps> = ({logoUrl, name, size = 92, rounded = true}) => {
  const sizeClasses = {
    16: 'max-w-[16px] h-[16px]',
    32: 'max-w-[32px] h-[32px]',
    64: 'max-w-[64px] h-[64px]',
    92: 'max-w-[92px] h-[92px]',
    180: 'max-w-[180px] h-[180px]',
  };
  const textSizeClasses = {
    16: 'text-xs',
    32: 'text-sm',
    64: 'text-base',
    92: 'text-lg',
    180: 'text-xl',
  }

  return (
    <>
      {logoUrl && (
        <span
          className={`${sizeClasses[size]} ${rounded ? 'rounded-full' : 'rounded-sm'} shrink-0 flex justify-center items-center`}
        >
          <CloudImage
            visibleByDefault
            alt={name}
            src={logoUrl}
            height={180}
            width={180}
            quality={'100'}
            crop="scale"
            style={{borderRadius: '9999px'}}
          />
        </span>
      )}
      {!logoUrl && (
        <span
          className={`${sizeClasses[size]}  bg-codGray200 ${rounded ? 'rounded-full' : 'rounded-sm'} shrink-0 flex justify-center items-center`}
        >
          <Heading
            as="span"
            className={`!${textSizeClasses[size]}`}
          >
            {name?.slice(0, 2).toUpperCase()}
          </Heading>
        </span>
      )}
    </>
  );
};
