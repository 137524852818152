import {BrandSearchResponse, PartnershipStatus} from '~/gql/generated';
import {FC, useState} from 'react';
import {Modal, Textarea} from 'flowbite-react';

import {Button} from '~/modules/Common/components/Button';
import {ExpertBrandResponseInfo} from './ExpertBrandResponseInfo';
import {GeneralError} from '~/modules/Brand/Onboarding/GeneralError';
import {Heading} from '~/modules/Common/components/Heading';
import {Text} from '~/modules/Common/components/Text';
import {useExpertLink} from '~/modules/Common/hooks/useExpertLink';
import {useFetcher} from '@remix-run/react';
import {useFetcherSuccessCb} from '~/modules/Common/hooks/useFetcherSuccessCb';

interface BrandApplyModalProps {
  onClose: () => void;
  onSuccess: (note: string) => void;
  brand: BrandSearchResponse;
  productId?: string;
}

export const BrandApplyModal: FC<BrandApplyModalProps> = ({
  onClose,
  onSuccess,
  brand,
  productId,
}) => (
  <Modal dismissible show size={'xl'} className="h-screen" onClose={onClose}>
    <Modal.Header>
      <Heading size="headlineSm" as="span">
        Apply to Sell
      </Heading>
    </Modal.Header>
    <BrandApplyModalContent
      onClose={onClose}
      brand={brand}
      onSuccess={onSuccess}
      productId={productId}
    />
  </Modal>
);

const BrandApplyModalContent: FC<BrandApplyModalProps> = ({
  onClose,
  onSuccess,
  brand,
  productId,
}) => {
  const [note, setNote] = useState('');
  const fetcher = useFetcher();
  const actionLink = useExpertLink('/my/discover');
  useFetcherSuccessCb(() => onSuccess(note), fetcher, [onClose]);
  return (
    <>
      <Modal.Body>
        <ExpertBrandResponseInfo brand={brand} />
        <div className="pt-4">
          <Text size="copyHeadline">
            Tell {brand.name || brand.companyInfo?.name} why you want to sell
            their products (optional)
          </Text>
          <Textarea
            className="mt-2"
            rows={5}
            value={note}
            onChange={(e) => setNote(e.target.value)}
            placeholder="Mention if you already promote their products, if you own them, or anything else you want the brand to know"
            helperText={`Your email address will be shared with ${
              brand.name || brand.companyInfo?.name
            } for any communications`}
          />
        </div>
        <GeneralError error={fetcher?.data?.error} />
      </Modal.Body>
      <Modal.Footer>
        <fetcher.Form
          method="post"
          action={actionLink}
          className="flex flex-row justify-between w-full flex-auto"
        >
          <input type="hidden" name="action" value={'apply'} />
          <input type="hidden" name="brandId" value={brand.id} />
          <input type="hidden" name="note" value={note} />
          <input
            type="hidden"
            name="status"
            value={PartnershipStatus.PendingApproval}
          />
          <input type="hidden" name="productId" value={productId} />
          <Button type="button" onClick={onClose} color="light">
            Cancel
          </Button>
          <Button type="submit" isLoading={fetcher.state !== 'idle'}>
            Apply
          </Button>
        </fetcher.Form>
      </Modal.Footer>
    </>
  );
};
