import {FC} from 'react';

interface GrayYoutubeProps {
  width?: number;
  height?: number;
}

export const GrayYoutube: FC<GrayYoutubeProps> = ({
  width = 16,
  height = 16,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="8" fill="black" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1532 4.55511C12.6119 4.67599 12.9747 5.03191 13.098 5.48185C13.3239 6.30114 13.3307 8.00016 13.3307 8.00016C13.3307 8.00016 13.3307 9.7059 13.1048 10.5185C12.9816 10.9684 12.6187 11.3243 12.16 11.4452C11.3316 11.6668 7.9974 11.6668 7.9974 11.6668C7.9974 11.6668 4.66321 11.6668 3.83479 11.4452C3.37609 11.3243 3.01323 10.9684 2.88999 10.5185C2.66406 9.69919 2.66406 8.00016 2.66406 8.00016C2.66406 8.00016 2.66406 6.30114 2.88315 5.48856C3.00638 5.03862 3.36924 4.6827 3.82795 4.56182C4.65636 4.34021 7.99055 4.3335 7.99055 4.3335C7.99055 4.3335 11.3247 4.3335 12.1532 4.55511ZM9.6953 8.00016L6.92936 9.57159V6.42873L9.6953 8.00016Z"
        fill="white"
      />
    </svg>
  );
};
