import {Text} from '~/modules';
import {FC, PropsWithChildren} from 'react';

interface ComponentWithLabelProps {
  label: string;
  className?: string;
  textSize?: 'lead' | 'copy' | 'fine' | 'subtext' | 'sm' | 'copyHeadline';
}

const ComponentWithLabel: FC<PropsWithChildren<ComponentWithLabelProps>> = ({
  label,
  textSize = 'fine',
  className,
  children,
}) => {
  return (
    <div className={`${className} mt-2 flex-1`}>
      <Text size={textSize} as="p" internal>
        {label}
      </Text>
      <div className="mt-2">{children}</div>
    </div>
  );
};

export default ComponentWithLabel;
