import {Product, ShoppingType} from '~/gql/generated';
import type {
  ProductVariant,
  SelectedOptionInput,
  Product as ShopifyProduct,
} from '@shopify/hydrogen/storefront-api-types';

export function getSelectedVariant(
  product: Product,
  shopifyProduct: ShopifyProduct,
  selectedOptions: SelectedOptionInput[],
) {
  if (
    [
      ShoppingType.Partial,
      ShoppingType.Affiliate,
      ShoppingType.Synthetic,
    ].includes(product?.shoppingType)
  ) {
    return (selectedOptions || []).length > 0
      ? matchVariantToSelectedOptions(product.variants, selectedOptions) ||
          findFirstAvailableVariant(product.variants)
      : findFirstAvailableVariant(product.variants);
  } else {
    if (!shopifyProduct) {
      return product.variants?.[0];
    }
    if (shopifyProduct.selectedVariant && selectedOptions.length > 0) {
      return shopifyProduct.selectedVariant;
    }

    if (!shopifyProduct.selectedVariant && selectedOptions.length > 0) {
      return matchShopifyVariantToSelectedOptions(
        shopifyProduct.variants.nodes,
        selectedOptions,
      );
    }
    return findFirstAvailableVariant(shopifyProduct.variants.nodes);
  }
}

function matchShopifyVariantToSelectedOptions(
  variants: ProductVariant[],
  selectedOptions: SelectedOptionInput[],
) {
  return (variants || [])
    .filter(Boolean)
    .find((variant) =>
      variant.selectedOptions.every((variantOption) =>
        selectedOptions.some(
          (selectedOption) =>
            selectedOption.name === variantOption.name &&
            selectedOption.value === variantOption.value,
        ),
      ),
    );
}

function findFirstAvailableVariant(variants: ProductVariant[]) {
  if (!variants) {
    return null;
  }
  return variants.find((variant) => variant.availableForSale) || variants[0];
}

function matchVariantToSelectedOptions(
  variants: ProductVariant[],
  selectedOptions: SelectedOptionInput[],
) {
  return (variants || [])
    .filter(Boolean)
    .find((variant) =>
      variant.options.every((variantOption) =>
        selectedOptions.some(
          (selectedOption) =>
            selectedOption.name === variantOption.key &&
            selectedOption.value === variantOption.value,
        ),
      ),
    );
}
