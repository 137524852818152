import {Heading} from './Heading';
import clsx from 'clsx';

export function PageHeader({
  children,
  className,
  heading,
  variant = 'default',
  ...props
}: {
  children?: React.ReactNode;
  className?: string;
  heading?: string;
  variant?: 'default' | 'blogPost' | 'allCollections';
  [key: string]: any;
}) {
  const variants: Record<string, string> = {
    default: 'grid w-full gap-8 p-4 py-8 md:p-8 justify-items-start',
    blogPost:
      'grid md:text-center w-full gap-4 p-4 py-8 md:p-8  md:justify-items-center',
    allCollections: 'flex justify-between items-baseline gap-8 py-2 px-4',
  };

  const styles = clsx(variants[variant], className);

  return (
    <header {...props} className={styles}>
      {heading && (
        <Heading as="h1" width="narrow" size="heading" className="inline-block">
          {heading}
        </Heading>
      )}
      {children}
    </header>
  );
}
