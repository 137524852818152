import {Heading} from '~/modules';
import clsx from 'clsx';
import {missingClass} from '~/lib/utils';

export function Section({
  as: Component = 'section',
  children,
  className,
  headingClassName,
  divider = 'none',
  display = 'grid',
  heading,
  padding = 'all',
  maxWidth,
  ...props
}: {
  as?: React.ElementType;
  children?: React.ReactNode;
  className?: string;
  headingClassName?: string;
  divider?: 'none' | 'top' | 'bottom' | 'both';
  display?: 'grid' | 'flex';
  heading?: string;
  padding?: 'x' | 'y' | 'swimlane' | 'all' | 'default';
  maxWidth?: 'lg';
  [key: string]: any;
}) {
  const paddings = {
    x: 'px-4 md:px-8 lg:px-12',
    y: 'py-4 md:py-8',
    swimlane: 'pt-4 lg:pt-12 md:pb-4 lg:pb-8',
    all: 'px-4 py-2',
    default: 'px-4 py-4 md:pt-8 lg:pt-12 md:pb-4 lg:pb-8',
  };

  const dividers = {
    none: 'border-none',
    top: 'border-t border-primary/05',
    bottom: 'border-b border-primary/05',
    both: 'border-y border-primary/05',
  };

  const displays = {
    flex: 'flex',
    grid: 'grid',
  };

  const styles = clsx(
    'w-full gap-4',
    displays[display],
    missingClass(className, '\\mp[xy]?-') && paddings[padding],
    dividers[divider],
    className,
    maxWidth === 'lg' ? 'max-w-5xl mx-auto' : '',
  );

  return (
    <Component {...props} className={styles}>
      {heading && (
        <Heading
          size="lead"
          className={`${padding === 'y' ? paddings['x'] : ''} ${
            headingClassName || ''
          }`}
        >
          {heading}
        </Heading>
      )}
      {children}
    </Component>
  );
}
