import {Await, useFetcher, useMatches, useNavigation} from '@remix-run/react';

import {Button} from '~/modules';
import {ButtonShapes} from '~/modules/Appearance/types';
import {CartAction} from '~/lib/type';
import type {CartLineInput} from '@shopify/hydrogen/storefront-api-types';
import {Suspense} from 'react';
import {getDiscountCodeList} from '../utils';
import {useExpert} from '~/modules/Common/hooks/useExpert';

export function AddToCartButton({
  children,
  lines,
  className = '',
  formClassName = '',
  variant = 'primary',
  width = 'full',
  analytics,
  code = '',
  isBuyNow = false,
  shape = ButtonShapes.rounded,
  textColor,
  backgroundColor,
  ...props
}: {
  children: React.ReactNode;
  lines: CartLineInput[];
  className?: string;
  formClassName?: string;
  variant?: 'primary' | 'secondary' | 'inline';
  width?: 'auto' | 'full';
  analytics?: unknown;
  code?: string;
  isBuyNow?: boolean;
  shape?: ButtonShapes;
  textColor?: string;
  backgroundColor?: string;
  [key: string]: any;
}) {
  const [root] = useMatches();
  const fetcher = useFetcher();
  const {state} = useNavigation();
  const {expertSlug, isSubdomain, theme} = useExpert();

  const selectedLocale = root?.data?.selectedLocale;

  const buttonProps = {
    disabled: state !== 'idle',
    type: 'submit',
    fullSized: true,
    color: 'expert',
    className: `${className} ${state !== 'idle' ? 'opacity-50' : ''}`,
    ...props,
  };

  return (
    <Suspense
      fallback={
        <Button
          {...buttonProps}
          shape={shape}
          textColor={textColor}
          backgroundColor={backgroundColor}
        >
          {children}
        </Button>
      }
    >
      <Await resolve={root.data?.cartData}>
        {(data) => {
          return (
            <fetcher.Form
              action={`${!isSubdomain ? `/${expertSlug}` : ''}/cart`}
              method="post"
              className={formClassName || ''}
            >
              <input
                type="hidden"
                name="cartAction"
                value={CartAction.ADD_TO_CART}
              />
              <input
                type="hidden"
                name="countryCode"
                value={selectedLocale.country}
              />
              <input type="hidden" name="lines" value={JSON.stringify(lines)} />
              <input
                type="hidden"
                name="analytics"
                value={JSON.stringify(analytics)}
              />
              <input type="hidden" name="discount" value={code} />
              <input type="hidden" name="expertName" value={expertSlug} />
              <input
                type="hidden"
                name="storeName"
                value={theme?.store?.name || expertSlug}
              />
              <input
                type="hidden"
                name="discountCodes"
                value={getDiscountCodeList(data?.cartProducts, code)}
              />
              <Button
                shape={shape}
                textColor={textColor}
                backgroundColor={backgroundColor}
                {...buttonProps}
                {...(isBuyNow ? {href: data?.cart?.checkoutUrl} : {})}
              >
                {children}
              </Button>
            </fetcher.Form>
          );
        }}
      </Await>
    </Suspense>
  );
}
