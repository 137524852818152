import {FC, useState} from 'react';
import {Label, Modal, TextInput, Textarea, ToggleSwitch} from 'flowbite-react';

import {BottomDrawer} from '~/modules/Common/components/BottomDrawer';
import {Button} from '~/modules/Common/components/Button';
import {Collection} from '~/gql/generated';
import {GeneralError} from '~/modules/Brand/Onboarding/GeneralError';
import {Heading} from '~/modules/Common/components/Heading';
import {MergedProduct} from '~/modules/Product/context/types';
import {useCreateCollection} from '../hooks/useCreateCollection';

interface CreateCollectionsModalProps {
  product: MergedProduct;
  productIds?: string[];
  onClose: () => void;
  onSuccess: (collection: Collection) => void;
  className?: string;
}

export const CreateCollectionsModal: FC<CreateCollectionsModalProps> = ({
  product,
  productIds,
  onClose,
  onSuccess,
  className = '',
}) => {
  const Content = (
    <Layout
      product={product}
      productIds={productIds}
      onClose={onClose}
      onSuccess={onSuccess}
    />
  );
  return (
    <>
      <BottomDrawer className={`md:hidden ${className}`} onClose={onClose}>
        {Content}
      </BottomDrawer>
      <Modal
        className={`hidden md:flex h-screen ${className}`}
        onClose={onClose}
        show
        dismissible
        size="md"
      >
        <Modal.Header>
          <Heading size="headlineSm" as="span">
            Create a New Collection
          </Heading>
        </Modal.Header>
        <Modal.Body className="flex flex-row justify-center gap-8 !py-6">
          {Content}
        </Modal.Body>
      </Modal>
    </>
  );
};
const Layout: FC<CreateCollectionsModalProps> = ({
  product,
  productIds,
  onSuccess,
}) => {
  const [data, setData] = useState({
    collectionId: '',
    name: '',
    description: '',
    bannerImageUrl: '',
    displayInNavigation: false,
    displayInHomePage: false,
    productIds: productIds || [product?.id],
  });
  const createCollection = useCreateCollection();
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const onSubmit = async () => {
    setIsSubmitting(true);
    const res = await createCollection({
      ...data,
    });

    if (res.success) {
      onSuccess(res.collection);
    }
    if (res.error) {
      setError(res.error);
    }
    setIsSubmitting(false);
  };

  return (
    <div>
      <div className="flex-grow max-w-[400px] flex flex-col gap-4">
        <div>
          <div className="mb-2 block">
            <Label htmlFor="name">Collection name</Label>
          </div>
          <TextInput
            required
            minLength={2}
            type="text"
            id="name"
            name="name"
            value={data.name}
            onChange={(event) =>
              setData((prevData) => ({
                ...prevData,
                name: event.target.value,
              }))
            }
          />
        </div>
        <div>
          <div className="mb-2 block">
            <Label htmlFor="description">Description</Label>
          </div>
          <Textarea
            id="description"
            name="description"
            placeholder="Write text here..."
            rows="5"
            theme={{
              base: 'text-sm w-full rounded-md',
            }}
            value={data.description}
            onChange={(event) =>
              setData((prevData) => ({
                ...prevData,
                description: event.target.value,
              }))
            }
          />
        </div>
      </div>
      <div>
        <Heading size="headlineSm" as="h3">
          Display Settings
        </Heading>
        <div className="flex-grow max-w-[400px] flex flex-col gap-2 mt-2">
          <ToggleSwitch
            checked={data.displayInNavigation}
            label="Display in site navigation (links)"
            onChange={(checked) =>
              setData((prevData) => ({
                ...prevData,
                displayInNavigation: checked,
              }))
            }
          />
          <ToggleSwitch
            checked={data.displayInHomePage}
            label="Display on homepage"
            onChange={(checked) =>
              setData((prevData) => ({
                ...prevData,
                displayInHomePage: checked,
              }))
            }
          />
        </div>
        <GeneralError error={error} />
      </div>
      <div className="mt-2 py-2 bg-white">
        <Button
          fullSized
          onClick={onSubmit}
          disabled={isSubmitting}
          isLoading={isSubmitting}
        >
          Create Collection
        </Button>
      </div>
    </div>
  );
};
