import {AffiliateSubType, ShoppingType} from '~/gql/generated';

import {AmazonLogo} from '~/modules/Common/AmazonLogo';
import {BrandLogo} from '~/modules/Brand/components/BrandLogo';
import {FC} from 'react';
import {MergedProduct} from '../../context/types';
import {PriceDisplay} from './../ProductPrice';
import {ProductImage} from '../ProductImage';
import {Text} from '~/modules';
import clsx from 'clsx';
import {getDomainFromLink} from '~/modules/Common/util';
import {useCardImage} from '../../hooks/useCardImage';
import {useProductPrice} from '../../hooks/useProductPrice';

interface StorefrontCardProps {
  product: MergedProduct;
  className?: string;
  visibleByDefault?: boolean;
}

export const StorefrontCard: FC<StorefrontCardProps> = ({
  product,
  className,
  visibleByDefault = false,
}) => {
  const image = useCardImage(product);
  const {isOnSale, compareAtPrice, price, discount} = useProductPrice(product);
  const isAffiliate = product?.shoppingType === ShoppingType.Affiliate;

  return (
    <div
      className={clsx(
        'group flex flex-col justify-between gap-2 p-2 rounded-lg border-[1px] border-solid border-transparent hover:border-codGray hover:shadow-storefront overflow-hidden h-full',
        className,
      )}
    >
      {image && (
        <div className="aspect-square bg-codGray50 rounded-lg relative overflow-hidden">
          <ProductImage product={product} visibleByDefault={visibleByDefault} />
        </div>
      )}
      <div className="flex-auto flex flex-col justify-between">
        <div className="flex flex-col px-2">
          <Text
            className="w-full overflow-hidden text-ellipsis text-xs text-codGray800 uppercase"
            as="h3"
          >
            {product.brandName}
          </Text>

          <Text
            className="w-full line-clamp-2 text-ellipsis text-codGray"
            as="h4"
            size="copy"
          >
            {product.name}
          </Text>
        </div>
        <div className="flex flex-col px-2 pb-2">
          {!isAffiliate && product.brandType !== AffiliateSubType.Amazon && (
            <PriceDisplay
              className="flex gap-2 font-medium"
              isOnSale={isOnSale}
              compareAtPrice={compareAtPrice}
              price={price}
              size="text-[18px]"
              discount={discount}
              coupon={{ type: product.couponType as string, value: product.couponVal as string}}
            />
          )}
          {(product.affiliateSubType === AffiliateSubType.Amazon || product.brandType === AffiliateSubType.Amazon) && (
            <AmazonLogo />
          )}
          {product.affiliateSubType === AffiliateSubType.Impact && (
            <Text size="sm" as="p" className={`center-row gap-1`}>
              <BrandLogo
                size={16}
                name={product.brandName}
                logoUrl={product.brandLogoUrl}
              />
              {product.brandName}
            </Text>
          )}
        </div>
      </div>
    </div>
  );
};
