import {Link, Text} from '~/modules';

import {MenuItem} from '../types';

export function MenuMobileNav({
  menu,
  onClose,
}: {
  menu: MenuItem[];
  onClose: () => void;
}) {
  return (
    <nav className="grid gap-4 p-6 sm:gap-6 sm:px-12 sm:py-8">
      {/* Top level menu items */}
      {(menu || []).map((item) => (
        <span key={item.href} className="block">
          <Link
            to={item.href}
            target={item.isExternal ? '_blank' : '_self'}
            onClick={onClose}
            className={({isActive}) =>
              isActive ? 'pb-1 border-b -mb-px' : 'pb-1'
            }
            reloadDocument
          >
            <Text as="span" size="copy">
              {item.label}
            </Text>
          </Link>
        </span>
      ))}
    </nav>
  );
}
