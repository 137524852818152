import {useEffect, useMemo} from 'react';

import {useCartFetchers} from '~/modules/Common/hooks/useCartFetchers';
import {useDrawer} from '~/modules/Common/components/Drawer';

export const useCartState = () => {
  const {
    isOpen: isCartOpen,
    openDrawer: openCart,
    closeDrawer: closeCart,
  } = useDrawer();

  const addToCartFetchers = useCartFetchers('ADD_TO_CART');

  useEffect(() => {
    if (isCartOpen || !addToCartFetchers.length) return;
    openCart();
  }, [addToCartFetchers, isCartOpen, openCart]);

  const isLoading = useMemo(
    () => Boolean(addToCartFetchers?.find((f) => f.state !== 'idle')),
    [addToCartFetchers],
  );

  return {
    isLoading,
    isCartOpen,
    openCart,
    closeCart,
  };
};
