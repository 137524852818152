import {MergedProduct} from '../context/types';
import {getMediaProps} from '~/modules/Common/util';

export function useCardImage(product?: MergedProduct) {
  if (!product) return null;
  const firstImage = (product.media || []).find((med) => {
    const {isVideo} = getMediaProps(med);
    return !isVideo;
  });

  if (firstImage) {
    return getMediaProps(firstImage);
  }

  return firstImage;
}
