import {Container} from './Container';
import {FC} from 'react';
import {Heading} from '~/modules/Common/components/Heading';
import {Text} from '~/modules/Common/components/Text';
import {getCloudinarySource} from '~/modules/Common/components/CloudImage';
import {useMatches} from '@remix-run/react';

interface HeroProps {
  image: {src: string; alt: string};
  mobileImage?: {src: string; alt: string};
  title: string;
  subtitle?: string;
  imageClassName?: string;
  containerClassName?: string;
}

export const Hero: FC<HeroProps> = ({
  image,
  mobileImage,
  title,
  subtitle,
  imageClassName = '',
  containerClassName = '',
}) => {
  const [root] = useMatches();
  const src = image?.src || mobileImage?.src;

  const Content = (
    <>
      <div
        className=" absolute bottom-0 top-[40%] md:top-[50%] left-0 right-0"
        style={{
          background:
            'linear-gradient(to top, rgba(0, 0, 0, 0.55), transparent)',
        }}
      />

      <div className="absolute bottom-0 py-4 px-4">
        <Heading size="heading" as="h1" className="block text-white text-h1">
          {title}
        </Heading>
        {subtitle && (
          <Text as="p" className="block text-white text-md pt-2">
            {subtitle}
          </Text>
        )}
      </div>
    </>
  );
  const desktop = image?.src
    ? getCloudinarySource({
        src: `${image.src}#desktop`,
        currentPath: root.data.domain,
        env: root.data.ENV.PUBLIC_ENV,
        width: 1600,
        height: 450,
        faceFocus: true,
        crop: 'fill',
        dpr: '2.0',
        quality: '90',
      })
    : null;

  const mobile = mobileImage?.src
    ? getCloudinarySource({
        src: `${mobileImage?.src || image.src}#mobile`,
        currentPath: root.data.domain,
        env: root.data.ENV.PUBLIC_ENV,
        width: 768,
        height: 576,
        faceFocus: true,
        crop: 'fill',
        dpr: '2.0',
        quality: '90',
      })
    : null;

  return src ? (
    <Container>
      {mobile && <link href={mobile} rel="preload" as="image" />}
      {desktop && <link href={desktop} rel="preload" as="image" />}
      <div
        className={`hidden md:block relative mx-auto h-[50vh] bg-cover ${
          imageClassName || 'bg-top'
        }`}
        style={{
          backgroundColor: '#171717',
          backgroundImage: `url(${desktop})`,
        }}
      >
        {Content}
      </div>
      <div
        className={`md:hidden relative mx-auto h-[40vh] max-h-[320px] bg-cover ${
          imageClassName || 'bg-top'
        }`}
        style={{
          backgroundColor: '#171717',
          backgroundImage: `url(${mobile})`,
        }}
      >
        {Content}
      </div>
    </Container>
  ) : (
    <NoImageHero
      title={title}
      subtitle={subtitle}
      containerClassName={containerClassName}
    />
  );
};

const NoImageHero: FC<Omit<HeroProps, 'image'>> = ({
  title,
  subtitle,
  containerClassName,
}) => {
  return (
    <div className="w-full">
      <div
        className={`relative mx-auto`}
        style={{
          background:
            'linear-gradient(270deg, #F34A62 0%, #BB77ED 50.52%, #1CDCE8 100%)',
        }}
      >
        <Container usePadding>
          <div
            className={`flex flex-col items-center justify-center h-[175px] md:h-[185px] ${containerClassName}`}
          >
            <Heading
              size="heading"
              as="h1"
              className="block text-white text-h1 font-normal text-center"
            >
              {title}
            </Heading>
            <Text as="p" className="block text-white text-center text-md pt-2">
              {subtitle}
            </Text>
          </div>
        </Container>
      </div>
    </div>
  );
};
