export function formatLink(link = '', replacement = '') {
  const updated = removeHttpAndWww(link.replace(replacement, ''));
  return updated.startsWith('/') ? updated.substring(1) : updated;
}

function removeHttpAndWww(link = '') {
  return link.replace(/^(https?:\/\/)?(www\.)?/, '');
}

export function requireAt(str: string) {
  return str.startsWith('@') ? str : `@${str}`;
}
