import {Drawer} from '~/modules';
import {MenuItem} from '../types';
import {MenuMobileNav} from './MenuMobileNav';

export function MenuDrawer({
  isOpen,
  onClose,
  menu,
}: {
  isOpen: boolean;
  onClose: () => void;
  menu: MenuItem[];
}) {
  return (
    <Drawer open={isOpen} onClose={onClose} openFrom="left" heading="Menu">
      <div className="overflow-y-auto max-h-[calc(100vh-130px)]">
        <MenuMobileNav menu={menu} onClose={onClose} />
      </div>
    </Drawer>
  );
}
