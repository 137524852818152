import {FC} from 'react';

interface GrayFacebookProps {
  width?: number;
  height?: number;
}

export const GrayFacebook: FC<GrayFacebookProps> = ({
  width = 16,
  height = 16,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8" cy="8" r="8" fill="black" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.1144 10.3123L11.4691 7.9997H9.25027V6.49897C9.25027 5.86629 9.56021 5.24959 10.5541 5.24959H11.5629V3.28077C11.5629 3.28077 10.6473 3.12451 9.77202 3.12451C7.94453 3.12451 6.75012 4.23205 6.75012 6.23713V7.9997H4.71875V10.3123H6.75012V15.9028C7.15744 15.9667 7.57492 16 8.00019 16C8.42547 16 8.84294 15.9667 9.25027 15.9028V10.3123H11.1144Z"
      fill="white"
    />
  </svg>
);
