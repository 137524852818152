import {Link, Text} from '~/modules';
import {formatLink, requireAt} from '../util';

import {Amex} from '../../../icons/Amex';
import {Discover} from '../../../icons/Discover';
import {FC} from 'react';
import {GrayFacebook} from '~/icons/GrayFacebook';
import {GrayInstagram} from '~/icons/GrayInstagram';
import {GrayPinterest} from '~/icons/GrayPinterest';
import {GrayThreads} from '~/icons/GrayThreads';
import {GrayTiktok} from '~/icons/GrayTiktok';
import {GrayTwitter} from '~/icons/GrayTwitter';
import {GrayYoutube} from '~/icons/GrayYoutube';
import {Mastercard} from '../../../icons/Mastercard';
import {Visa} from '../../../icons/Visa';
import {Wayward} from '~/icons/Wayward';
import {useExpert} from '~/modules/Common/hooks/useExpert';

export const Footer: FC<{isPortal?: boolean}> = ({isPortal}) => {
  const {theme, socialMedia} = useExpert();
  const {name, social} = theme?.store || {};
  const title =
    name || theme.v2?.name || theme.v2?.title || theme.v2?.name || '';

  return (
    <footer
      role="contentinfo"
      className="flex flex-col gap-4 items-center border-solid border-t-[1px] border-codGray100 py-8"
    >
      {name && (
        <Link to={'/'}>
          <Text className="uppercase">{title}</Text>
        </Link>
      )}
      {(social || Object.keys(socialMedia || {}).length > 0) && (
        <div className="flex gap-4 flex-row flex-nowrap justify-center items-center">
          {social?.facebook && !socialMedia?.facebookName && (
            <a
              href={formatFacebookLink(social.facebook)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <GrayFacebook />
            </a>
          )}
          {social?.instagram && !socialMedia?.instagramName && (
            <a
              href={formatInstagramLink(social.instagram)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <GrayInstagram />
            </a>
          )}
          {social?.pinterest && !socialMedia?.pinterestName && (
            <a
              href={formatPinterestLink(social.pinterest)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <GrayPinterest />
            </a>
          )}
          {socialMedia.instagramName && (
            <a
              href={`https://instagram.com/${formatLink(
                socialMedia.instagramName,
                'instagram.com',
              )}`}
              target="_blank"
              rel="noreferrer"
            >
              <GrayInstagram />
            </a>
          )}
          {socialMedia.facebookName && (
            <a
              href={`https://facebook.com/${formatLink(
                socialMedia.facebookName,
                'facebook.com',
              )}`}
              target="_blank"
              rel="noreferrer"
            >
              <GrayFacebook />
            </a>
          )}
          {socialMedia.tiktokName && (
            <a
              href={`https://tiktok.com/${requireAt(
                formatLink(socialMedia.tiktokName, 'https://tiktok.com'),
              )}`}
              target="_blank"
              rel="noreferrer"
            >
              <GrayTiktok />
            </a>
          )}
          {socialMedia.pinterestName && (
            <a
              href={`https://pinterest.com/${formatLink(
                socialMedia.pinterestName,
                'pinterest.com',
              )}`}
              target="_blank"
              rel="noreferrer"
            >
              <GrayPinterest />
            </a>
          )}
          {socialMedia.youtubeName && (
            <a
              href={`https://youtube.com/${requireAt(
                formatLink(socialMedia.youtubeName, 'youtube.com'),
              )}`}
              target="_blank"
              rel="noreferrer"
            >
              <GrayYoutube />
            </a>
          )}
          {socialMedia.twitterName && (
            <a
              href={`https://twitter.com/${formatLink(
                socialMedia.twitterName,
                'twitter.com',
              )}`}
              target="_blank"
              rel="noreferrer"
            >
              <GrayTwitter />
            </a>
          )}
          {socialMedia.threadsName && (
            <a
              href={`https://threads.net/${formatLink(
                socialMedia.threadsName,
                'threads.net',
              )}`}
              target="_blank"
              rel="noreferrer"
            >
              <GrayThreads />
            </a>
          )}
        </div>
      )}

      <a
        className="text-sm font-bold"
        href={isPortal ? '/creator-terms' : '/terms'}
        target="_blank"
      >
        Terms and Conditions
      </a>
      <a className="text-sm font-bold" href="/privacy-policy" target="_blank">
        Privacy Policy
      </a>
      <div className="flex gap-1 flex-row pt-4 flex-nowrap justify-center items-center">
        <Visa />
        <Mastercard />
        <Amex />
        <Discover />
        <span className="text-xs text-codGray600">and more...</span>
      </div>
      <a href="/" className="uppercase flex flex-row gap-1 pt-4 items-center">
        Powered By <Wayward width={114} height={30} />
      </a>
      <Link to="/join" className="text-sm font-bold">
        Sell your brand in Wayward powered stores
      </Link>
      <a href="/" className="text-sm font-bold">
        Start your own store with Wayward
      </a>
      <Text size="sm" as="p" className="text-center">
        As an Amazon Associate, we earn from qualifying purchases.
      </Text>
    </footer>
  );
};

function formatInstagramLink(link = '') {
  link = link.replace('@', '');
  return link.startsWith('https://www.instagram.com/')
    ? link
    : link.replace(/^(?!http)/, 'https://www.instagram.com/');
}

function formatFacebookLink(link = '') {
  return link.includes('facebook.com')
    ? link
    : `https://www.facebook.com/${link}`;
}

function formatPinterestLink(link = '') {
  return link.includes('pinterest.ca') || link.includes('pinterest.com')
    ? link
    : `https://www.pinterest.ca/${link}`;
}
