import {Await, useFetcher, useMatches} from '@remix-run/react';
import {Button, CartLoading, Heading, Link, Text} from '~/modules';
import type {
  CartCost,
  CartLine,
  CartLineUpdateInput,
  Cart as CartType,
} from '@shopify/hydrogen/storefront-api-types';
import {FC, Suspense, useMemo, useRef} from 'react';
import {Money, flattenConnection} from '@shopify/hydrogen';

import {AnalyticsEvent} from '~/modules/Common/hooks/analyticsEvent';
import {ButtonShapes} from '~/modules/Appearance/types';
import {CartAction} from '~/lib/type';
import {CloudImage} from '~/modules/Common/components/CloudImage';
import {PortalSpinner} from '~/modules/Common/components/PortalSpinner';
import {Product} from '~/gql/generated';
import {TrashIcon} from '@heroicons/react/outline';
import {WaywardShort} from '~/icons/WaywardShort';
import clsx from 'clsx';
import {getDiscountCodeList} from '../utils';
import {useExpert} from '~/modules/Common/hooks/useExpert';
import {useRudderStack} from '~/modules/Common/hooks/useRudderstack';
import {useScroll} from 'react-use';

type Layouts = 'page' | 'drawer';

export const Cart = ({
  layout,
  onClose,
  cart,
  isLoading,
  cartProducts,
}: {
  layout: Layouts;
  onClose?: () => void;
  cart: CartType | null;
  isLoading?: boolean;
  cartProducts?: Product[];
}) =>
  isLoading ? (
    <CartLoading />
  ) : (
    <>
      <CartEmpty
        hidden={Boolean(cart?.lines?.edges?.length || 0)}
        onClose={onClose}
        layout={layout}
      />
      <CartDetails cart={cart} layout={layout} cartProducts={cartProducts} />
    </>
  );

export function CartDetails({
  layout,
  cart,
  cartProducts,
}: {
  layout: Layouts;
  cart: CartType | null;
  cartProducts?: Product[];
}) {
  const container = {
    drawer: 'grid grid-cols-1 h-screen-no-nav grid-rows-[1fr_auto]',
    page: 'w-full pb-12 grid md:grid-cols-2 md:items-start gap-8 md:gap-8 lg:gap-12',
  };

  return (
    <div className={container[layout]}>
      <CartLines
        lines={cart?.lines}
        layout={layout}
        cartProducts={cartProducts}
      />

      {cart && (
        <CartSummary cost={cart.cost} layout={layout}>
          <CartDiscounts discountCodes={cart.discountCodes} />
          <CartCheckoutActions checkoutUrl={cart.checkoutUrl} />
        </CartSummary>
      )}
    </div>
  );
}

/**
 * Temporary discount UI
 * @param discountCodes the current discount codes applied to the cart
 * @todo rework when a design is ready
 */
function CartDiscounts({
  discountCodes,
}: {
  discountCodes: CartType['discountCodes'];
}) {
  const codes = discountCodes?.map(({code}) => code).join(', ') || null;

  return (
    <>
      {/* Have existing discount, display it with a remove option */}
      <dl className={codes ? 'grid' : 'hidden'}>
        <div className="flex items-center justify-between font-medium">
          <Text as="dt">{`Discount${
            (codes || '').split(',').length > 1 ? 's' : ''
          }`}</Text>

          <Text className="pl-2 text-right text-sm" as="dd">
            {codes}
          </Text>
        </div>
      </dl>
    </>
  );
}

function CartLines({
  layout = 'drawer',
  lines: cartLines,
  cartProducts,
}: {
  layout: Layouts;
  lines: CartType['lines'] | undefined;
  cartProducts?: Product[];
}) {
  const currentLines = cartLines ? flattenConnection(cartLines) : [];
  const scrollRef = useRef(null);
  const {y} = useScroll(scrollRef);

  const className = clsx([
    y > 0 ? 'border-t' : '',
    layout === 'page'
      ? 'flex-grow md:translate-y-4'
      : 'px-6 pb-6 sm-max:pt-2 overflow-auto transition md:px-12',
  ]);

  return (
    <section
      ref={scrollRef}
      aria-labelledby="cart-contents"
      className={className}
    >
      <ul className="grid gap-6 md:gap-10">
        {currentLines.map((line) => (
          <CartLineItem
            key={line.id}
            line={line as CartLine}
            cartProducts={cartProducts}
          />
        ))}
      </ul>
    </section>
  );
}

const CartCheckoutActions: FC<{checkoutUrl: string}> = ({checkoutUrl}) => {
  const {theme, expertName} = useExpert();
  const {trackEvent} = useRudderStack();
  const {name} = theme?.store || {};
  const storeTitle =
    name || theme.v2?.name || theme.v2?.title || theme.v2?.name || '';

  if (!checkoutUrl) return null;

  return (
    <div className="flex flex-col mt-2">
      <a href={checkoutUrl} target="_self">
        <Button
          fullSized
          color="expert"
          shape={theme?.v2?.buttonShape || ButtonShapes.rounded}
          textColor={theme?.v2?.buttonFontColor || ''}
          backgroundColor={theme?.v2?.buttonColor || ''}
          className="justify-center"
          onClick={() => {
            trackEvent(AnalyticsEvent.continueToCheckout, {
              expertName,
            });
          }}
        >
          <span>Continue to Checkout</span>
        </Button>
      </a>
      <Text
        size="sm"
        as="p"
        width="full"
        className="justify-center mt-4 flex flex-col gap-2 items-center"
      >
        <span>{storeTitle}</span>
        <span className="center-row gap-2">
          Powered by
          <WaywardShort />
        </span>
        <span className="sr-only">Wayward</span>
      </Text>
    </div>
  );
};

function CartSummary({
  cost,
  layout,
  children = null,
}: {
  children?: React.ReactNode;
  cost: CartCost;
  layout: Layouts;
}) {
  const summary = {
    drawer: 'grid gap-4 p-6 border-t md:px-12',
    page: 'sticky top-nav grid gap-6 p-4 md:px-6 md:translate-y-4 bg-primary/5 rounded w-full',
  };

  return (
    <section aria-labelledby="summary-heading" className={summary[layout]}>
      <h2 id="summary-heading" className="sr-only">
        Order summary
      </h2>
      <dl className="grid">
        <div className="flex items-center justify-between font-medium">
          <Text as="dt">Subtotal</Text>
          <Text as="dd" data-test="subtotal">
            {cost?.subtotalAmount?.amount ? (
              <Money data={cost?.subtotalAmount} />
            ) : (
              '-'
            )}
          </Text>
        </div>
      </dl>
      {children}
    </section>
  );
}

function CartLineItem({
  line,
  cartProducts,
}: {
  line: CartLine;
  cartProducts?: Product[];
}) {
  const cartProduct = useMemo(
    () =>
      cartProducts?.find(
        (p) => p.externalId === line?.merchandise?.product?.id,
      ),
    [cartProducts, line?.merchandise?.product?.id],
  );
  const productId = useMemo(() => cartProduct?.id || '', [cartProduct]);
  if (!line?.id) return null;
  const {id, quantity, merchandise} = line;
  if (typeof quantity === 'undefined' || !merchandise?.product) return null;

  return (
    <li key={id} className="flex gap-4">
      <div className="basis-[100px] flex-none aspect-square bg-codGray50 rounded-lg ">
        {merchandise.image && (
          <CloudImage
            visibleByDefault
            width={350}
            height={350}
            src={merchandise.image?.url}
            className="object-cover object-center border fadeIn"
            alt={merchandise.title}
            style={{
              mixBlendMode: 'multiply',
            }}
          />
        )}
      </div>
      <div className="flex justify-between flex-grow">
        <div className="grid gap-2">
          <Heading as="h3" size="copy">
            {merchandise?.product?.handle ? (
              <Link to={`/products/${merchandise.product.handle}/${productId}`}>
                {merchandise?.product?.title || ''}
              </Link>
            ) : (
              <span>{merchandise?.product?.title || ''}</span>
            )}
          </Heading>

          <div className="grid pb-2">
            {(merchandise?.selectedOptions || []).map(
              (option) =>
                option.value !== 'Default Title' && (
                  <Text color="subtle" size="sm" key={option.name}>
                    {option.name}: {option.value}
                  </Text>
                ),
            )}
          </div>

          <div className="flex items-center gap-2">
            <div className="flex justify-start text-copy">
              <CartLineQuantityAdjust
                line={line}
                step={cartProduct?.setSize || 1}
              />
            </div>
            <ItemRemoveButton lineIds={[id]} id={merchandise?.product?.id} />
          </div>
        </div>
        <Text>
          <CartLinePrice line={line} as="span" />
        </Text>
      </div>
    </li>
  );
}

function ItemRemoveButton({
  lineIds,
  id,
}: {
  lineIds: CartLine['id'][];
  id: string;
}) {
  const fetcher = useFetcher();
  const [root] = useMatches();
  const {expertSlug, isSubdomain} = useExpert();
  const Content = (
    <Button color="white" type="submit" className="border !p-2.5">
      <span className="sr-only">Remove</span>
      {fetcher.state !== 'idle' ? (
        <PortalSpinner />
      ) : (
        <TrashIcon height={20} width={20} />
      )}
    </Button>
  );

  return (
    <Suspense fallback={Content}>
      <Await resolve={root.data?.cartData}>
        {(data) => (
          <fetcher.Form
            action={`${!isSubdomain ? `/${expertSlug}` : ''}/cart`}
            method="post"
          >
            <input
              type="hidden"
              name="cartAction"
              value={CartAction.REMOVE_FROM_CART}
            />
            <input
              type="hidden"
              name="linesIds"
              value={JSON.stringify(lineIds)}
            />
            <input
              type="hidden"
              name="discountCodes"
              value={getDiscountCodeList(
                (data?.cartProducts || []).filter((p) => p.externalId !== id),
              )}
            />
            {Content}
          </fetcher.Form>
        )}
      </Await>
    </Suspense>
  );
}

function CartLineQuantityAdjust({
  line,
  step = 1,
}: {
  line: CartLine;
  step?: number;
}) {
  if (!line || typeof line?.quantity === 'undefined') return null;
  const {id: lineId, quantity} = line;
  const prevQuantity = Number(Math.max(0, quantity - step).toFixed(0));
  const nextQuantity = Number((quantity + step).toFixed(0));

  return (
    <>
      <label htmlFor={`quantity-${lineId}`} className="sr-only">
        Quantity, {quantity}
      </label>
      <div className="flex items-center border rounded">
        <UpdateCartButton lines={[{id: lineId, quantity: prevQuantity}]}>
          <button
            name="decrease-quantity"
            aria-label="Decrease quantity"
            className="w-10 h-10 transition text-primary/50 hover:text-primary disabled:text-primary/10"
            value={prevQuantity}
            disabled={quantity <= 1}
          >
            <span>&#8722;</span>
          </button>
        </UpdateCartButton>
        <div className="px-2 text-center" data-test="item-quantity">
          {quantity}
        </div>
        <UpdateCartButton lines={[{id: lineId, quantity: nextQuantity}]}>
          <button
            className="w-10 h-10 transition text-primary/50 hover:text-primary"
            name="increase-quantity"
            value={nextQuantity}
            aria-label="Increase quantity"
          >
            <span>&#43;</span>
          </button>
        </UpdateCartButton>
      </div>
    </>
  );
}

function UpdateCartButton({
  children,
  lines,
}: {
  children: React.ReactNode;
  lines: CartLineUpdateInput[];
}) {
  const fetcher = useFetcher();
  const {expertSlug, isSubdomain} = useExpert();
  return (
    <fetcher.Form
      action={`${!isSubdomain ? `/${expertSlug}` : ''}/cart`}
      method="post"
    >
      <input type="hidden" name="cartAction" value={CartAction.UPDATE_CART} />
      <input type="hidden" name="lines" value={JSON.stringify(lines)} />
      {children}
    </fetcher.Form>
  );
}

function CartLinePrice({
  line,
  priceType = 'regular',
  ...passthroughProps
}: {
  line: CartLine;
  priceType?: 'regular' | 'compareAt';
  [key: string]: any;
}) {
  if (!line?.cost?.amountPerQuantity || !line?.cost?.totalAmount) return null;

  const moneyV2 =
    priceType === 'regular'
      ? line.cost.totalAmount
      : line.cost.compareAtAmountPerQuantity;

  if (moneyV2 == null) {
    return null;
  }

  return <Money withoutTrailingZeros {...passthroughProps} data={moneyV2} />;
}

export function CartEmpty({
  hidden = false,
  layout = 'drawer',
  onClose,
}: {
  hidden: boolean;
  layout?: Layouts;
  onClose?: () => void;
}) {
  const scrollRef = useRef(null);
  const {y} = useScroll(scrollRef);
  const {theme} = useExpert();

  const container = {
    drawer: clsx([
      'content-start gap-4 px-6 pb-8 transition overflow-y-scroll md:gap-12 md:px-12 h-screen-no-nav md:pb-12',
      y > 0 ? 'border-t' : '',
    ]),
    page: clsx([
      hidden ? '' : 'grid',
      `pb-12 w-full md:items-start gap-4 md:gap-8 lg:gap-12`,
    ]),
  };

  return (
    <div ref={scrollRef} className={container[layout]} hidden={hidden}>
      <section className="grid gap-6">
        <Text format>
          Looks like you haven&rsquo;t added anything yet, let&rsquo;s get you
          started!
        </Text>
        <Button
          onClick={onClose}
          fullSized
          shape={theme?.v2?.buttonShape || ButtonShapes.rounded}
          textColor={theme?.v2?.buttonFontColor || ''}
          backgroundColor={theme?.v2?.buttonColor || ''}
        >
          Continue shopping
        </Button>
      </section>
    </div>
  );
}
