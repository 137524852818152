import {BrandSearchResponse, PartnershipStatus, Product} from '~/gql/generated';
import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {isProductIngested, mergeProducts} from '../utils/productUtils';

import {BrandApplyModal} from '~/modules/Expert/components/BrandApplyModal';
import {MergedProduct} from './types';
import {ProductPreviewModal} from '../components/ProductPreviewModal';
import {Product as ShopifyProduct} from '@shopify/hydrogen/storefront-api-types';
import {TimedToast} from '~/modules/Common/components/TimedToast';

interface ProductsContextInterface {
  shopifyProducts: ShopifyProduct[];
  apiProducts: Product[];
  products: MergedProduct[];
  onProductBrandApplyClick: (product: MergedProduct) => void;
  onCardClick: (product: MergedProduct, commssion: string) => void;
  getIsEnabled: (product: MergedProduct) => boolean;
  getIsApplicationPending: (product: MergedProduct) => boolean;
  setProduct: (product: MergedProduct) => void;
}

type ProductsProviderProps = PropsWithChildren<{
  shopifyProducts: ShopifyProduct[];
  apiProducts: Product[];
  infoMap?: Record<
    string,
    {requiresApproval: boolean; partnershipStatus: PartnershipStatus}
  >;
  isBrandEnabled?: boolean;
  source?: 'default' | 'recommendation';
}>;

export const ProductsContext = createContext<ProductsContextInterface>({
  shopifyProducts: [],
  apiProducts: [],
  products: [],
  onProductBrandApplyClick: () => {},
  onCardClick: () => {},
  getIsEnabled: () => true,
  getIsApplicationPending: () => false,
  setProduct: () => {},
});

export const ProductsProvider: FC<ProductsProviderProps> = (props) => {
  const [products, setProducts] = useState<MergedProduct[]>(
    mergeProducts(props.apiProducts, props.shopifyProducts),
  );
  useEffect(() => {
    setProducts(mergeProducts(props.apiProducts, props.shopifyProducts));
  }, [props.apiProducts, props.shopifyProducts]);
  const [isApplyModalOpen, setIsApplyModalOpen] = useState(false);
  const [applyModalBrand, setApplyModalBrand] = useState<BrandSearchResponse>();
  const [showBrandApplySuccessToast, setShowBrandApplySuccessToast] =
    useState(false);

  const onProductBrandApplyClick = (product: MergedProduct) => {
    setApplyModalBrand(productToBrand(product));
    setIsApplyModalOpen(true);
  };
  const [previewModalState, setPreviewModalState] = useState<{
    isOpen: boolean;
    product?: MergedProduct;
    commissionValue?: string;
  }>({isOpen: false, product: undefined, commissionValue: undefined});

  const onCardClick = useCallback(
    (product: MergedProduct, commissionValue: string) => {
      setPreviewModalState({isOpen: true, product, commissionValue});
    },
    [],
  );

  const getIsEnabled = useCallback(
    (product: MergedProduct) => {
      const isIngested = isProductIngested(product);
      const infoMap = props.infoMap;
      if (typeof props.isBrandEnabled === 'boolean') {
        return props.isBrandEnabled;
      }
      if (infoMap && infoMap[product.id] && isIngested) {
        //requiresApproval && partnershipStatus !== PartnershipStatus.Approved
        const {requiresApproval, partnershipStatus} = infoMap[product.id] || {};
        return (
          !requiresApproval ||
          (requiresApproval && partnershipStatus === PartnershipStatus.Approved)
        );
      }
      return true;
    },
    [props.infoMap, props.isBrandEnabled],
  );

  const getIsApplicationPending = useCallback(
    (product: MergedProduct) => {
      const isIngested = isProductIngested(product);
      const infoMap = props.infoMap;
      if (infoMap && infoMap[product.id] && isIngested) {
        const {partnershipStatus} = infoMap[product.id] || {};
        return partnershipStatus === PartnershipStatus.PendingApproval;
      }
      return false;
    },
    [props?.infoMap],
  );

  const setProduct = useCallback(
    (product: MergedProduct) => {
      let index = products.findIndex((p) => p.id === product.id);
      if (index === -1) {
        index = products.findIndex((p) => p.externalId === product.externalId);
      }
      if (index > -1) {
        const nextProducts = [...products];
        nextProducts[index] = product;
        setProducts(nextProducts);
      }
      if (product.externalId === previewModalState.product?.externalId) {
        setPreviewModalState((prev) => ({...prev, product}));
      }
    },
    [products, previewModalState],
  );

  return (
    <ProductsContext.Provider
      value={{
        ...props,
        products,
        onProductBrandApplyClick,
        onCardClick,
        getIsEnabled,
        getIsApplicationPending,
        setProduct,
      }}
    >
      {props.children}
      {isApplyModalOpen && applyModalBrand && (
        <BrandApplyModal
          brand={applyModalBrand}
          onClose={() => {
            setIsApplyModalOpen(false);
            setApplyModalBrand(undefined);
          }}
          onSuccess={() => {
            setIsApplyModalOpen(false);
            setShowBrandApplySuccessToast(true);
          }}
        />
      )}
      {previewModalState.isOpen && previewModalState.product && (
        <ProductPreviewModal
          product={previewModalState.product}
          onClose={() => setPreviewModalState({isOpen: false})}
          source={props.source || 'default'}
          commission={previewModalState.commissionValue!}
          hideCreate={!getIsEnabled(previewModalState.product)}
        />
      )}
      <TimedToast
        message="Your application has been submitted"
        isSuccess={showBrandApplySuccessToast}
        parentSetter={() => setShowBrandApplySuccessToast(false)}
      />
    </ProductsContext.Provider>
  );
};

function productToBrand(product: Product): Partial<BrandSearchResponse> {
  return {
    id: product.brandId,
    name: product.brandName,
    logoUrl: product.brandLogoUrl,
  };
}
