import {ReactNode, useCallback, useMemo, useState} from 'react';

import {CartDrawer} from '../../Cart/components/CartDrawer';
import {DesktopHeader} from './DesktopHeader';
import {LogoTypes} from '~/modules/Appearance/types';
import {MenuDrawer} from './MenuDrawer';
import {MenuItem} from '../types';
import {MobileHeader} from './MobileHeader';
import {SearchAutocomplete} from '~/modules/Search/components/SearchAutocomplete';
import {isValidUrl} from '~/modules/Common/util';
import {useCartState} from '../hooks/useCartState';
import {useDrawer} from '~/modules';
import {useExpert} from '~/modules/Common/hooks/useExpert';
import {useIsHomePath} from '~/lib/utils';

export function Header({
  title,
  logo,
  logoType,
}: {
  title: string | ReactNode;
  logo: string;
  logoType?: LogoTypes;
}) {
  const {theme, expertNav} = useExpert();
  const {nav} = theme?.store || {};

  const menu: MenuItem[] = useMemo(() => {
    const m = [
      ...(expertNav || []).map((n) => ({
        label: n.name,
        href: `/collections/${encodeURIComponent(n.slug)}/${n.id}`,
      })),
    ];

    if (nav?.home && isValidUrl(fixUrl(nav?.home))) {
      m.push({
        label: 'Go to Main Site',
        href: fixUrl(nav?.home),
        isExternal: true,
      });
    }
    return m;
  }, [nav, expertNav]);

  const isHome = useIsHomePath();

  const {
    isOpen: isMenuOpen,
    openDrawer: openMenu,
    closeDrawer: closeMenu,
  } = useDrawer();

  const {isCartOpen, closeCart, openCart, isLoading} = useCartState();
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const openSearch = useCallback(() => {
    document.body.classList.add(`overflow-hidden`);
    setIsSearchOpen(true);
  }, []);

  const closeSearch = useCallback(() => {
    document.body.classList.remove(`overflow-hidden`);
    setIsSearchOpen(false);
  }, []);

  return (
    <div className="relative">
      <CartDrawer
        isOpen={isCartOpen}
        onClose={closeCart}
        isLoading={isLoading}
      />
      {menu && (
        <MenuDrawer isOpen={isMenuOpen} onClose={closeMenu} menu={menu} />
      )}
      <DesktopHeader
        isHome={isHome}
        title={title}
        logo={logo}
        logoType={logoType}
        menu={menu}
        openCart={openCart}
        openSearch={openSearch}
        isSearchOpen={isSearchOpen}
      />
      <MobileHeader
        isHome={isHome}
        title={title}
        logo={logo}
        logoType={logoType}
        openCart={openCart}
        openMenu={openMenu}
        openSearch={openSearch}
        isSearchOpen={isSearchOpen}
        hasMenu={(menu || []).filter(({label}) => !!label).length > 0}
      />
      {isSearchOpen && (
        <div className="absolute inset-0 z-[100000] bg-white md:bg-glass h-screen w-full">
          <SearchAutocomplete
            onCancelClick={closeSearch}
            onItemClick={closeSearch}
          />
        </div>
      )}
    </div>
  );
}

function fixUrl(url: string) {
  if (url.startsWith('http')) {
    return url;
  }
  return `https://${url}`;
}
