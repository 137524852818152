import {CloudImage} from '~/modules/Common/components/CloudImage';
import {FC} from 'react';
import {MergedProduct} from '../../context/types';
import type {MoneyV2} from '@shopify/hydrogen/storefront-api-types';
import {PriceDisplay} from './../ProductPrice';
import {StarRating} from '~/modules/Reviews/components/StarRating';
import {Text} from '~/modules';
import clsx from 'clsx';
import {useCardImage} from '../../hooks/useCardImage';
import {useProductPrice} from '../../hooks/useProductPrice';

interface CarouselProductCardProps {
  product: MergedProduct;
  className?: string;
}

export const CarouselProductCard: FC<CarouselProductCardProps> = ({
  product,
  className,
}) => {
  const {compareAtPrice, price, discount, isOnSale} = useProductPrice(product);

  const image = useCardImage(product);

  return (
    <div className={clsx('grid gap-2', className)}>
      <div className={`aspect-square bg-codGray50 rounded-lg relative`}>
        {image && (
          <CloudImage
            className={`absolute top-0 w-full left-0 fadeIn group-hover:opacity-50`}
            width={350}
            height={350}
            src={image.src!}
            alt={image.alt || `Picture of ${product.name}`}
            style={{
              mixBlendMode: 'multiply',
            }}
          />
        )}
      </div>
      <div className="grid gap-1">
        <Text
          className="w-full overflow-hidden line-clamp-2 text-codGray"
          as="h3"
        >
          {product.name}
        </Text>

        <div className="flex gap-2 font-medium">
          <PriceDisplay
            isOnSale={isOnSale}
            compareAtPrice={compareAtPrice}
            price={price as MoneyV2}
            size="text-md"
            discount={discount}
            coupon={{ type: product.couponType as string, value: product.couponVal as string}}
          />
        </div>
      </div>
    </div>
  );
};
