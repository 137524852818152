import {FC} from 'react';
import {MergedProduct} from '~/modules/Product/context/types';
import {ToggleSwitch} from 'flowbite-react';
import {useToggleProductVisibilty} from '../hooks/useToggleProductVisibility';

interface ProductVisibilityToggleProps {
  product: MergedProduct;
  expertName: string;
  showLabel?: boolean;
}

export const ProductVisibilityToggle: FC<ProductVisibilityToggleProps> = ({
  product,
  expertName,
  showLabel = true,
}) => {
  const toggleProductVisibility = useToggleProductVisibilty(
    expertName,
    product,
  );
  return (
    <ToggleSwitch
      checked={!!product.isVisibleInStore}
      label={showLabel ? 'Show in store' : ''}
      onChange={async () => {
        product.isVisibleInStore = !product.isVisibleInStore;
        await toggleProductVisibility(product.isVisibleInStore);
      }}
      theme={{
        root: {
          base: 'flex flex-col gap-1 items-center',
          label: 'text-fine text-black font-medium pt-1 md:pt-0',
        },
      }}
    />
  );
};
