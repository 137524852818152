import {Checkbox, Modal} from 'flowbite-react';
import {FC, useCallback, useEffect, useRef, useState} from 'react';

import {AnalyticsEvent} from '~/modules/Common/hooks/analyticsEvent';
import {Button} from '~/modules/Common/components/Button';
import {Collection} from '~/gql/generated';
import {CreateCollectionsModal} from '~/modules/Expert/components/CreateCollectionsModal';
import {Heading} from '~/modules/Common/components/Heading';
import {MergedProduct} from '~/modules/Product/context/types';
import {PlusIcon} from '@heroicons/react/outline';
import {PortalSpinner} from '~/modules/Common/components/PortalSpinner';
import {Text} from '~/modules/Common/components/Text';
import {useExpert} from '~/modules/Common/hooks/useExpert';
import {useGetCollections} from '~/modules/Expert/hooks/useGetCollections';
import {useMatches} from '@remix-run/react';
import {useRudderStack} from '~/modules/Common/hooks/useRudderstack';
import {useUpdateCollections} from '~/modules/Expert/hooks/useUpdateCollections';

interface CollectionsQuickModalProps {
  onClose: (isSuccess: boolean) => void;
  product?: MergedProduct;
  productIds?: string[];
  source?: 'default' | 'recommendation';
}

export const CollectionsQuickModal: FC<CollectionsQuickModalProps> = ({
  onClose,
  product,
  productIds,
  source,
}) => {
  const [root] = useMatches();
  const {trackEvent} = useRudderStack();
  const {expertName} = useExpert();
  const getCollections = useGetCollections();
  const updateCollections = useUpdateCollections();
  const [collections, setCollections] = useState<Collection[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const loadCollections = useCallback(async () => {
    setIsLoading(true);
    const result = await getCollections();
    setCollections(result);
    setIsLoading(false);
  }, [getCollections]);

  useEffect(() => {
    loadCollections();
  }, [loadCollections]);

  const onSubmit = async (
    collectionIds: string[],
    initialCollectionIds: string[],
  ) => {
    const remove = initialCollectionIds.filter(
      (id) => !collectionIds.includes(id),
    );
    const add = collectionIds.filter(
      (id) => !initialCollectionIds.includes(id),
    );
    await updateCollections(
      product?.id ? [product.id] : productIds,
      add,
      remove,
    );
    if (source === 'recommendation' && product) {
      trackEvent(AnalyticsEvent.recommendationAddToCollection, {
        productId: product.id,
        creatorName: expertName,
        creatorId: root.data?.userData?.expert?.id || '',
      });
    }
    loadCollections();
    onClose(true);
  };

  return (
    <>
      <Modal
        className="h-screen z-[2147483002]"
        dismissible
        show
        onClose={() => onClose(false)}
        size="lg"
      >
        <Modal.Header className="py-2">
          <Heading size="headlineSm" as="span" internal>
            Add Product{productIds?.length > 0 ? 's' : ''} to Collection
          </Heading>
        </Modal.Header>
        <BodyLayout
          onClose={onClose}
          product={product}
          collections={collections}
          onSubmit={onSubmit}
          onCollectionCreated={() => loadCollections()}
          isLoading={isLoading}
          productIds={productIds}
        />
      </Modal>
    </>
  );
};

const rowClass =
  'flex flex-row w-full items-center justify-between py-2 border-solid border-b-[1px] border-codGray100 ';
const BodyLayout: FC<
  CollectionsQuickModalProps & {
    collections: Collection[];
    onSubmit: (collectionIds: string[], initialCollections: string[]) => void;
    onCollectionCreated: () => void;
    isLoading: boolean;
  }
> = ({
  onSubmit,
  product,
  collections,
  onCollectionCreated,
  isLoading,
  productIds,
}) => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const initialCollections = useRef(
    getCollectionsWithProduct(collections, productIds || [product?.id]),
  );
  const [collectionsWithProduct, setCollectionsWithProduct] = useState<
    string[]
  >(initialCollections.current);
  const [hasChecked, setHasChecked] = useState(false);
  const onChange = useCallback(
    (collection: Collection) => {
      setHasChecked(true);
      if (collectionsWithProduct.includes(collection.id)) {
        setCollectionsWithProduct(
          collectionsWithProduct.filter((id) => id !== collection.id),
        );
      } else {
        setCollectionsWithProduct([...collectionsWithProduct, collection.id]);
      }
    },
    [collectionsWithProduct],
  );

  useEffect(() => {
    setCollectionsWithProduct(
      getCollectionsWithProduct(collections, productIds || [product?.id]),
    );
  }, [collections, product, productIds]);

  return isLoading ? (
    <Modal.Body className="pt-0">
      <div className="flex flex-col items-center justify-center min-h-[200px]">
        <PortalSpinner size="lg" />
      </div>
    </Modal.Body>
  ) : (
    <>
      <Modal.Body className="!p-0">
        <button
          className={`${rowClass} flex-auto py-4 px-2`}
          onClick={() => setIsCreateModalOpen(true)}
        >
          <Text size="sm" as="span">
            Create a new collection
          </Text>
          <PlusIcon height={18} width={18} />
        </button>
        <div>
          <Heading size="copyBold" as="h3" className="my-4 px-2">
            Choose collection(s) to add product:
          </Heading>
          <ul className="flex flex-col gap-2 overflow-auto max-h-[55vh]">
            {collections.map((collection) =>
              collection.name ? (
                <li
                  key={collection.id}
                  className={`${rowClass} last-of-type:border-none px-2`}
                  onClick={() => onChange(collection)}
                >
                  <Text size="sm" as="span">
                    {collection.name}
                  </Text>
                  <Checkbox
                    checked={collectionsWithProduct.includes(collection.id)}
                    onChange={() => onChange(collection)}
                  />
                </li>
              ) : null,
            )}
          </ul>
        </div>
      </Modal.Body>
      <Modal.Footer className="py-2">
        <Button
          fullSized
          disabled={!hasChecked}
          onClick={() =>
            onSubmit(collectionsWithProduct, initialCollections.current)
          }
        >
          Add to Collection
        </Button>
      </Modal.Footer>
      {isCreateModalOpen && (
        <CreateCollectionsModal
          className="z-[2147483003]"
          product={product}
          productIds={productIds}
          onClose={() => setIsCreateModalOpen(false)}
          onSuccess={(collection: Collection) => {
            setIsCreateModalOpen(false);
            onChange(collection);
            onCollectionCreated();
          }}
        />
      )}
    </>
  );
};

function getCollectionsWithProduct(
  collections: Collection[],
  productIds: string[],
): string[] {
  return collections
    .filter((collection) =>
      productIds.every((id) => (collection.productIds || []).includes(id)),
    )
    .map((collection) => collection.id);
}
