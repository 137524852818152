import {FC} from 'react';

interface AmazonImageProps {
  width?: number;
  height?: number;
  className?: string;
  src: string;
  alt: string;
}

export const amazonImageLoader = (src: string, width?: number): string => {
  // trim out any existing resize sections
  const resizedSource = src
    ?.split('.')
    ?.filter(
      (section) => section[0] !== '_' && section[section.length - 1] !== '_',
    );

  if (!resizedSource) return '';
  // always resize to 256px
  resizedSource.splice(resizedSource.length - 1, 0, `_SX${width || '512'}_`);
  return `${resizedSource.join('.')}`;
};

export const AmazonImage: FC<AmazonImageProps> = ({
  src,
  alt,
  className,
  width,
}) => {
  return (
    <img src={amazonImageLoader(src, width) || '/images/no-image.png'} alt={alt} className={className} />
  );
};
